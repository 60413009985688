import axios from "axios";

const state = {
    llistaReclamacions: [],
    dadesMostrarATaula: [],
    detallsReclamacio: {},
    isBusy: false,
};
const getters = {
    getLlistaReclamacions: (state) => {
        return state.llistaReclamacions;
    },
    getDadesMostrarATaula: (state) => {
        return state.dadesMostrarATaula;
    },
    getRequestState: (state) => {
        return state.isBusy;
    },
    getDetallsReclamacio: (state) => {
        return state.detallsReclamacio;
    },
};
const mutations = {
    setLlistaReclamacions: (state, payload) => {
        state.llistaReclamacions = payload;
    },
    setDadesTaula: (state) => {
        let dadesTaula = [];

        for (let reclamacio of state.llistaReclamacions) {
            let dadesObj = {};
            dadesObj["Nº Expedició"] = reclamacio.numExpedicio;
            dadesObj["Creat el"] = reclamacio.reclamacioCreatEl;
            dadesTaula.push(dadesObj);
        }

        state.dadesMostrarATaula = dadesTaula;

        state.isBusy = false;
    },
    setDetallsReclamacio: (state, payload) => {
        state.detallsReclamacio = payload;
    },
};
const actions = {
    getReclamacions: async (context, payload) => {
        context.state.isBusy = true;
        await axios
            .get("vreclamacioTransportista/getReclamacionsTransportistes", {
                params: {
                    dataInici: payload.dataInici,
                    dataFi: payload.dataFi,
                    idTransportista: payload.idTransportista,
                },
            })
            .then((response) => {
                context.commit("setLlistaReclamacions", response.data);
                context.commit("setDadesTaula");
            })
            .catch();
    },
    setDetallsReclamacio: (context, payload) => {
        for (let reclamacio of context.state.llistaReclamacions) {
            if (reclamacio.numExpedicio === payload) {
                context.commit("setDetallsReclamacio", reclamacio);
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
