<template>
    <div class="dashboard-page">
        <h1 class="page-title">Bogar <small>Enterprise Resourse Planning</small></h1>
    </div>
</template>

<script>
export default {
    name: "Dashboard",
};
</script>

<style src="./Dashboard.scss" lang="scss" />
