import { render, staticRenderFns } from "./ReclamacionsTransportistesPortes.vue?vue&type=template&id=05bb97f1&scoped=true&"
import script from "./ReclamacionsTransportistesPortes.vue?vue&type=script&lang=js&"
export * from "./ReclamacionsTransportistesPortes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05bb97f1",
  null
  
)

export default component.exports