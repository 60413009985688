<template>
  <div class="novaFacturaProveidor">
    <b-breadcrumb>
      <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link :to="{ name: 'FacturesProveidors'}">Factures de proveïdors</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Nova factura de proveïdor</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">Factures de proveïdors</h2>
    <Widget :title="'Nova factura de proveïdor'">
      <Loader v-if="isReceiving" :size="40"></Loader>
      <template v-if="isReady">
        <b-form>
          <fieldset>
            <legend>Dades factura</legend>
            <b-form-row>
              <b-form-group
                horizontal
                label="Proveïdor *"
                :label-cols="2"
                label-breakpoint="md"
                label-for="idProveidor"
                class="col-md-6"
              >
                <v-select
                  v-validate="'required'"
                  name="idProveidor"
                  :class="{'is-invalid': errors.has('idProveidor')}"
                  id="idProveidor"
                  v-model="idProveidor"
                  :options="selects.proveidors"
                  index="value"
                  v-focus
                  @change="onChangeIdProveidor"
                />
                <FormError :attribute_name="'idProveidor'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Data *"
                label-cols="2"
                label-for="dataFactura"
                label-breakpoint="md"
                class="col-md-3"
              >
                <input
                  v-validate="'required|date_format:dd/MM/yyyy'"
                  name="dataFactura"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('dataFactura')}"
                  type="text"
                  id="dataFactura"
                  v-model="dataFactura"
                  placeholder="__/__/____"
                  v-mask="'##/##/####'"
                />
                <FormError :attribute_name="'dataFactura'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Núm.Factura *"
                label-cols="4"
                label-breakpoint="md"
                label-for="numFactura"
                class="col-md-3"
              >
                <input
                  v-validate="'required|max:31'"
                  name="numFactura"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('numFactura')}"
                  type="text"
                  id="numFactura"
                  v-model="numFactura"
                />
                <FormError :attribute_name="'numFactura'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group
                horizontal
                label="Pagada"
                :label-cols="3"
                label-breakpoint="md"
                label-for="dataPagament"
                class="col-md-3"
              >
                <input
                  v-validate="'date_format:dd/MM/yyyy'"
                  name="dataPagament"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('dataPagament')}"
                  type="text"
                  id="dataPagament"
                  v-model="dataPagament"
                  placeholder="__/__/____"
                  v-mask="'##/##/####'"
                />
                <FormError :attribute_name="'dataPagament'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Comprovat"
                :label-cols="3"
                label-breakpoint="md"
                label-for="comprovat"
                class="col-md-3"
              >
                <input
                  v-validate="'max:100'"
                  name="comprovat"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('comprovat')}"
                  type="text"
                  id="comprovat"
                  v-model="comprovat"
                />
                <FormError :attribute_name="'comprovat'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Observacions"
                :label-cols="2"
                label-breakpoint="md"
                label-for="observacions"
                class="col-md-6"
              >
                <textarea
                  v-validate="'max:1000'"
                  name="observacions"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('observacions')}"
                  :min-height="75"
                  id="observacions"
                  v-model="observacions"
                />
                <FormError :attribute_name="'observacions'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
            <legend>Confirming</legend>
            <b-form-row>
              <b-form-group
                horizontal
                label="Confirming"
                :label-cols="3"
                label-breakpoint="md"
                label-for="confirming"
                class="col-md-3"
              >
                <b-form-checkbox
                  name="confirming"
                  id="confirming"
                  v-model="confirming"
                  value="S"
                  unchecked-value="N"
                />
              </b-form-group>
              <b-form-group
                horizontal
                label="Venciment"
                :label-cols="3"
                label-breakpoint="md"
                label-for="dataVenciment"
                class="col-md-3"
              >
                <input
                  v-validate="'date_format:dd/MM/yyyy'"
                  name="dataVenciment"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('dataVenciment')}"
                  type="text"
                  id="dataVenciment"
                  v-model="dataVenciment"
                  placeholder="__/__/____"
                  v-mask="'##/##/####'"
                />
                <FormError :attribute_name="'dataVenciment'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Banc"
                :label-cols="1"
                label-breakpoint="md"
                label-for="bancConfirming"
                class="col-md-6"
              >
                <input
                  v-validate="'max:100'"
                  name="bancConfirming"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('bancConfirming')}"
                  type="text"
                  id="bancConfirming"
                  v-model="bancConfirming"
                />
                <FormError :attribute_name="'bancConfirming'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
            <legend></legend>
            <b-form-group
              horizontal
              label="Adjuntar factura"
              :label-cols="3"
              label-breakpoint="md"
              label-for="fileupload1"
            >
              <b-input-group class="fileinput fileinput-new">
                <input
                  @change="onFileChange"
                  id="fileupload1"
                  type="file"
                  name="file"
                  class="display-none"
                  accept="application/pdf"
                />
                <label for="fileupload1" class="form-control">
                  <div v-if="inputFiles.length > 0">
                    <span
                      v-for="file in inputFiles"
                      :key="`select-id-${file.name.split('.')[0].toString()}`"
                    >{{file.name}}</span>
                  </div>
                  <span v-else />
                </label>
                <b-input-group-append v-if="inputFiles.length === 0">
                  <b-button type="button" variant="default" class="btn-file">
                    <label for="fileupload1">Seleccionar fitxer</label>
                  </b-button>
                </b-input-group-append>
                <b-input-group-append v-else>
                  <b-button type="button" variant="default">
                    <label for="fileupload1">Change file</label>
                  </b-button>
                  <b-button type="reset" variant="default" @click="removeFiles">
                    <label>Remove file</label>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <span class="help-block">Adjuntar la factura escanejada en format PDF.</span>
            </b-form-group>
            <legend>
              <b-badge variant="warning">Concepte 1</b-badge>
            </legend>
            <b-form-row>
              <b-form-group
                horizontal
                label="Categoria "
                :label-cols="2"
                label-breakpoint="md"
                label-for="idCategoriaProveidor1"
                class="col-md-6"
              >
                <v-select
                  v-validate="'required'"
                  name="idCategoriaProveidor1"
                  ref="idCategoriaProveidor1"
                  :class="{'is-invalid': errors.has('idCategoriaProveidor1')}"
                  id="idCategoriaProveidor1"
                  v-model="idCategoriaProveidor1"
                  :options="selects.categories"
                  index="value"
                  @change="onChangeIdCategoriaProveidor1"
                />
                <FormError :attribute_name="'idCategoriaProveidor1'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Base Imp.(€) *"
                :label-cols="4"
                label-breakpoint="md"
                label-for="baseImp1"
                class="col-md-3"
              >
                <input
                  v-validate="'required'"
                  name="baseImp1"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('baseImp1')}"
                  type="text"
                  id="baseImp1"
                  v-model="baseImp1"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'baseImp1'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Tipus IVA"
                :label-cols="3"
                label-breakpoint="md"
                label-for="tipusIva1"
                class="col-md-3"
              >
                <v-select
                  v-validate="'required'"
                  name="tipusIva1"
                  :class="{'is-invalid': errors.has('tipusIva1')}"
                  id="tipusIva1"
                  v-model="tipusIva1"
                  :options="selects.tipusIva"
                  index="value"
                  ref="tipusIva1"
                  @change="onChangeTipusIva1"
                />
                <FormError :attribute_name="'tipusIva1'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group
                horizontal
                label="IVA (€)"
                :label-cols="2"
                label-breakpoint="md"
                label-for="iva1"
                class="col-md-4"
              >
                <b-form-input type="text" disabled :value="iva1" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Retenció(€) "
                :label-cols="2"
                label-breakpoint="md"
                label-for="retencio1"
                class="col-md-4"
              >
                <input
                  name="retencio1"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('retencio1')}"
                  type="text"
                  id="retencio1"
                  v-model="retencio1"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'retencio1'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Total (€)"
                :label-cols="2"
                label-breakpoint="md"
                label-for="total1"
                class="col-md-4"
              >
                <b-form-input type="text" disabled :value="total1" />
              </b-form-group>
            </b-form-row>
            <legend>
              <b-badge variant="warning">Concepte 2</b-badge>
            </legend>
            <b-form-row>
            <b-form-group
              horizontal
              label="Categoria "
              :label-cols="2"
              label-breakpoint="md"
              label-for="idCategoriaProveidor2"
              class="col-md-6"
            >
              <v-select
                name="idCategoriaProveidor2"
                ref="idCategoriaProveidor2"
                :class="{'is-invalid': errors.has('idCategoriaProveidor2')}"
                id="idCategoriaProveidor2"
                v-model="idCategoriaProveidor2"
                :options="selects.categories"
                index="value"
                @change="onChangeIdCategoriaProveidor2"
              />
              <FormError :attribute_name="'idCategoriaProveidor2'" :errors_form="errors" />
            </b-form-group>
            <b-form-group
              horizontal
              label="Base Imp.(€) *"
              :label-cols="4"
              label-breakpoint="md"
              label-for="baseImp2"
              class="col-md-3"
            >
              <input
                name="baseImp2"
                :class="{ 'form-control': true, 'is-invalid': errors.has('baseImp2')}"
                type="text"
                id="baseImp2"
                v-model="baseImp2"
                pattern="\d+(\.\d{2})?"
              />
              <FormError :attribute_name="'baseImp2'" :errors_form="errors" />
            </b-form-group>
            <b-form-group
              horizontal
              label="Tipus IVA"
              :label-cols="3"
              label-breakpoint="md"
              label-for="tipusIva2"
              class="col-md-3"
            >
              <v-select
                name="tipusIva2"
                :class="{'is-invalid': errors.has('tipusIva2')}"
                id="tipusIva2"
                v-model="tipusIva2"
                :options="selects.tipusIva"
                index="value"
                ref="tipusIva2"
                @change="onChangeTipusIva2"
              />
              <FormError :attribute_name="'tipusIva2'" :errors_form="errors" />
            </b-form-group>
            </b-form-row>
            <b-form-row>
            <b-form-group
              horizontal
              label="IVA (€)"
              :label-cols="2"
              label-breakpoint="md"
              label-for="iva2"
              class="col-md-4"
            >
              <b-form-input type="text" disabled :value="iva2" />
            </b-form-group>
            <b-form-group
              horizontal
              label="Retenció(€) "
              :label-cols="2"
              label-breakpoint="md"
              label-for="retencio2"
              class="col-md-4"
            >
              <input
                name="retencio2"
                :class="{ 'form-control': true, 'is-invalid': errors.has('retencio2')}"
                type="text"
                id="retencio2"
                v-model="retencio2"
                pattern="\d+(\.\d{2})?"
              />
              <FormError :attribute_name="'retencio2'" :errors_form="errors" />
            </b-form-group>
            <b-form-group
              horizontal
              label="Total (€)"
              :label-cols="2"
              label-breakpoint="md"
              label-for="total2"
              class="col-md-4"
            >
              <b-form-input type="text" disabled :value="total2" />
            </b-form-group>
            </b-form-row>
          </fieldset>
          <b-button-toolbar class="float-right">
            <b-button
              type="button"
              variant="default"
              class="btn-rounded"
              @click="$router.push({ name: 'FacturesProveidors'})"
            >
              <i class="fa fa-angle-left mr-xs mb-xs" />Tornar
            </b-button>
            <b-button variant="success" @click="onSubmit" class="btn-rounded">
              <Loader v-if="isUpdating"></Loader>
              <span v-else>
                <i class="fa fa-check mr-xs mb-xs" />Guardar
              </span>
            </b-button>
          </b-button-toolbar>
        </b-form>
      </template>
    </Widget>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "NovaFacturaProveidor",
  components: { vSelect },
  data() {
    return {
      inputFiles: []
    };
  },
  mounted() {
    this.initRowRequest({
      $toasted: this.$toasted
    });
  },
  computed: {
    ...mapState("facturesProveidors", [
      "isUpdating",
      "isReceiving",
      "isReady",
      "selects"
    ]),
    ...mapGetters("facturesProveidors", ["getRowValue"]),
    idProveidor: {
      get() {
        return this.getRowValue("idProveidor");
      },
      set(value) {
        this.setRowValue({ id: "idProveidor", value });
      }
    },
    dataFactura: {
      get() {
        return this.getRowValue("dataFactura");
      },
      set(value) {
        this.setRowValue({ id: "dataFactura", value });
      }
    },
    numFactura: {
      get() {
        return this.getRowValue("numFactura");
      },
      set(value) {
        this.setRowValue({ id: "numFactura", value });
      }
    },
    dataPagament: {
      get() {
        return this.getRowValue("dataPagament");
      },
      set(value) {
        this.setRowValue({ id: "dataPagament", value });
      }
    },
    comprovat: {
      get() {
        return this.getRowValue("comprovat");
      },
      set(value) {
        this.setRowValue({ id: "comprovat", value });
      }
    },
    observacions: {
      get() {
        return this.getRowValue("observacions");
      },
      set(value) {
        this.setRowValue({ id: "observacions", value });
      }
    },
    confirming: {
      get() {
        return this.getRowValue("confirming");
      },
      set(value) {
        this.setRowValue({ id: "confirming", value });
      }
    },
    dataVenciment: {
      get() {
        return this.getRowValue("dataVenciment");
      },
      set(value) {
        this.setRowValue({ id: "dataVenciment", value });
      }
    },
    bancConfirming: {
      get() {
        return this.getRowValue("bancConfirming");
      },
      set(value) {
        this.setRowValue({ id: "bancConfirming", value });
      }
    },
    idCategoriaProveidor1: {
      get() {
        return this.getRowValue("idCategoriaProveidor1");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaProveidor1", value });
      }
    },
    baseImp1: {
      get() {
        return this.getRowValue("baseImp1");
      },
      set(value) {
        this.setRowValue({ id: "baseImp1", value });
      }
    },
    retencio1: {
      get() {
        return this.getRowValue("retencio1");
      },
      set(value) {
        this.setRowValue({ id: "retencio1", value });
      }
    },
    tipusIva1: {
      get() {
        return this.getRowValue("tipusIva1");
      },
      set(value) {
        this.setRowValue({ id: "tipusIva1", value });
      }
    },
    iva1: function() {
      let iva = 0;
      if (
        this.getRowValue("baseImp1") != null &&
        this.getRowValue("percentatgeIva1")
      ) {
        iva = (
          (this.getRowValue("baseImp1") * this.getRowValue("percentatgeIva1")) /
          100
        ).toFixed(2);
      }
      return iva;
    },
    total1: function() {
      let total = 0;
      if (
        this.getRowValue("baseImp1") != null &&
        this.getRowValue("percentatgeIva1")
      ) {
        total =
          parseFloat(this.getRowValue("baseImp1")) +
          parseFloat(
            (
              (this.getRowValue("baseImp1") *
                this.getRowValue("percentatgeIva1")) /
              100
            ).toFixed(2)
          );
        if (this.getRowValue("retencio1") != null) {
          total = total - parseFloat(this.getRowValue("retencio1"));
        }
      }
      return total;
    },
    idCategoriaProveidor2: {
      get() {
        return this.getRowValue("idCategoriaProveidor2");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaProveidor2", value });
      }
    },
    baseImp2: {
      get() {
        return this.getRowValue("baseImp2");
      },
      set(value) {
        this.setRowValue({ id: "baseImp2", value });
      }
    },
    retencio2: {
      get() {
        return this.getRowValue("retencio2");
      },
      set(value) {
        this.setRowValue({ id: "retencio2", value });
      }
    },
    tipusIva2: {
      get() {
        return this.getRowValue("tipusIva2");
      },
      set(value) {
        this.setRowValue({ id: "tipusIva2", value });
      }
    },
    iva2: function() {
      let iva = 0;
      if (
        this.getRowValue("baseImp2") != null &&
        this.getRowValue("percentatgeIva2")
      ) {
        iva = (
          (this.getRowValue("baseImp2") * this.getRowValue("percentatgeIva2")) /
          100
        ).toFixed(2);
      }
      return iva;
    },
    total2: function() {
      let total = 0;
      if (
        this.getRowValue("baseImp2") != null &&
        this.getRowValue("percentatgeIva2")
      ) {
        total =
          parseFloat(this.getRowValue("baseImp2")) +
          parseFloat(
            (
              (this.getRowValue("baseImp2") *
                this.getRowValue("percentatgeIva2")) /
              100
            ).toFixed(2)
          );
        if (this.getRowValue("retencio2") != null) {
          total = total - parseFloat(this.getRowValue("retencio2"));
        }
      }
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapActions("facturesProveidors", [
      "createRowRequest",
      "initRowRequest",
      "setRowValue",
      "loadCategoriesDespeses",
      "loadTipusIva1",
      "loadTipusIva2",
      "loadPercentatgeIva1",
      "loadPercentatgeIva2"
    ]),
    onSubmit(e) {
      e.preventDefault();
      this.$validator
        .validateAll()
        .then(result => {
          if (!result) {
            return;
          }
          this.createRowRequest({
            $toasted: this.$toasted,
            push: { name: "EditarFacturaProveidor", params: { id: "id" } },
            missatge: "La factura de proveïdor ha estat creada!"
          });
        })
        .catch(() => {});
    },
    onChangeIdProveidor(value) {
      this.loadCategoriesDespeses(value);
      this.$refs.idCategoriaProveidor1.clearSelection();
      this.$refs.idCategoriaProveidor2.clearSelection();
    },
    onChangeIdCategoriaProveidor1(value) {
      this.loadTipusIva1(value);
      this.$refs.tipusIva1.clearSelection();
    },
    onChangeIdCategoriaProveidor2(value) {
      this.loadTipusIva2(value);
      this.$refs.tipusIva2.clearSelection();
    },
    onChangeTipusIva1(value) {
      this.loadPercentatgeIva1(value);
    },
    onChangeTipusIva2(value) {
      this.loadPercentatgeIva2(value);
    },
    onFileChange(e) {
      this.removeFiles();
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.inputFiles.push(files[0]);
      var reader = new FileReader();
      reader.onload = e => {
        this.setRowValue({ id: "dataFile", value: e.target.result });
      };
      reader.readAsDataURL(files[0]);
    },

    removeFiles() {
      this.inputFiles = [];
      this.setRowValue({ id: "dataFile", value: null });
    }
  }
};
</script>
