import { render, staticRenderFns } from "./RawDisplayer.vue?vue&type=template&id=4832c8ed&scoped=true&"
import script from "./RawDisplayer.vue?vue&type=script&lang=js&"
export * from "./RawDisplayer.vue?vue&type=script&lang=js&"
import style0 from "./RawDisplayer.vue?vue&type=style&index=0&id=4832c8ed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4832c8ed",
  null
  
)

export default component.exports