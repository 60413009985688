export default {
    methods: {
        formatBoolean(boolean) {
            return boolean ? "Si" : "No";
        },
        formatCurrency(value) {
            return value ? value + "€" : "";
        },
        resetFiltres(table) {
            let emptyFilter = {};
            for (var key in table.query) {
                emptyFilter[key] = '';
            }
            table.setFilter(emptyFilter);
            //table.resetQuery();            
            //table.refresh();      
        },
        getOpcions(opcions) {
            return {
                saveState: true,
                filterByColumn: true,
                perPage: 10,
                pagination: { chunk: 10, dropdown: false },
                texts: textsVueTable2,
                sortable: opcions.sortable,
                columns: opcions.columns,
                filterable: opcions.filterable,
                listColumns: opcions.listColumns,
                headings: opcions.headings,
                skin: 'table table-bordered table-hover'
            }
        },
        onLoading(queryData){
            this.queryData = queryData;
        },
        getQueryData(){
            return this.queryData;
        }

    },
    data() {
        return {
            queryData : []
        };
    }
};


export const textsVueTable2 = {
    count:
        "Mostrant del {from} al {to} de {count} registres|{count} registres|Un registre",
    first: "Primer",
    last: "Darrer",
    filter: "Filtre:",
    filterPlaceholder: "Search query",
    limit: "Registres:",
    page: "Pàgina:",
    noResults: "No s'ha trobat cap registre",
    filterBy: "Filtrar per {column}",
    loading: "Carregant...",
    defaultOption: "Seleccionar {column}",
    columns: "Columnes"
};

export const listSiNo = [
    {
        id: "S",
        text: "Si"
    },
    {
        id: "N",
        text: "No"
    }
];
