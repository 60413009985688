<template>
    <nav :class="{ sidebar: true, sidebarStatic, sidebarOpened }" @mouseenter="sidebarMouseEnter" @mouseleave="sidebarMouseLeave">
        <header class="logo">
            <router-link to="/app/main">
                <span v-if="sidebarOpened || sidebarStatic" class="text-warning">Bogar</span>
                <span v-else class="text-warning">B</span>
            </router-link>
        </header>
        <ul class="nav">
            <NavLink
                :activeItem="activeItem"
                header="Reclamacions"
                link="/app/reclamacions"
                iconName="flaticon-file"
                index="reclamacions"
                :childrenLinks="reclamacionsCategories"
            />
            <NavLink
                :activeItem="activeItem"
                header="Seguiment Comandes"
                link="/app/seguimentComandes"
                iconName="flaticon-calendar-5"
                index="seguimentComandes"
                isHeader
            />
            <NavLink
                :activeItem="activeItem"
                header="Factures de proveïdors"
                link="/app/facturesProveidors"
                iconName="flaticon-layers"
                index="facturesProveidors"
                isHeader
            />
            <NavLink
                :activeItem="activeItem"
                header="Mestres"
                link="/app/mestres"
                iconName="flaticon-server"
                index="mestres"
                :childrenLinks="mestresCategories"
            />
        </ul>
        <p></p>
    </nav>
</template>

<script>
import { mapState, mapActions } from "vuex";
import isScreen from "@/core/screenHelper";
import NavLink from "./NavLink/NavLink";

export default {
    name: "Sidebar",
    components: {
        NavLink,
    },
    data() {
        return {
            reclamacionsCategories: [
                {
                    header: "Proveïdors",
                    link: "/app/reclamacions/reclamacionsProveidors",
                    index: "reclamacionsProveidors",
                },
                {
                    header: "Transportistes",
                    link: "/app/reclamacions/reclamacionsTransportistes",
                    index: "reclamacionsTransportistes",
                    childrenLinks: [
                        {
                            header: "Productes",
                            link: "/app/reclamacions/reclamacionsTransportistes/productes",
                            index: "reclamacionsTransportistes_productes",
                        },
                        {
                            header: "Portes",
                            link: "/app/reclamacions/reclamacionsTransportistes/portes",
                            index: "reclamacionsTransportistes_portes",
                        },
                    ],
                },
                // {
                //     header: "Logistica",
                //     link: "app/reclamacions/reclamacionsLogistica",
                //     index: "reclamacionsLogistica",
                // },
            ],
            mestresCategories: [
                {
                    header: "Proveïdors",
                    link: "/app/mestres/mestresProveidors",
                    index: "mestresProveidors",
                    childrenLinks: [
                        {
                            header: "Proveïdors",
                            link: "/app/mestres/mestresProveidors/proveidors",
                            index: "mestresProveidors_proveidors",
                        },
                        {
                            header: "Categories despeses",
                            link: "/app/mestres/mestresProveidors/categoriesDespeses",
                            index: "mestresProveidors_categoriesDespeses",
                        },
                        {
                            header: "Centres de cost",
                            link: "/app/mestres/mestresProveidors/centresDeCost",
                            index: "mestresProveidors_centresDeCost",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        ...mapActions("layout", ["changeSidebarActive", "switchSidebar"]),
        setActiveByRoute() {
            const paths = this.$route.fullPath.split("/");
            paths.pop();
            this.changeSidebarActive(paths.join("/"));
        },
        sidebarMouseEnter() {
            if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
                this.switchSidebar(false);
                this.setActiveByRoute();
            }
        },
        sidebarMouseLeave() {
            if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
                this.switchSidebar(true);
                this.changeSidebarActive(null);
            }
        },
    },
    created() {
        this.setActiveByRoute();
    },
    computed: {
        ...mapState("layout", {
            sidebarStatic: (state) => state.sidebarStatic,
            sidebarOpened: (state) => !state.sidebarClose,
            activeItem: (state) => state.sidebarActiveElement,
        }),
    },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped />
