<template>
  <div class="editar-categoria-despesa">
    <b-breadcrumb>
      <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link :to="{ name: 'FacturesProveidors'}">Factures de proveïdors</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link
          :to="{ name: 'EditarFacturaProveidor', params: {id: $route.params.idFacturaProveidor}}"
        >{{jerarquia.numFactura}}</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Editar la categoria de despesa de la factura {{jerarquia.numFactura}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">Categoria de despesa de la factura {{jerarquia.numFactura}}</h2>
    <Widget :title="'Editar la categoria de despesa de la factura' + jerarquia.numFactura">
      <Loader v-if="isReceiving" :size="40"></Loader>
      <template v-if="isReady">
        <b-form>
          <fieldset>
            <b-form-row>
              <b-form-group
                horizontal
                label="Categoria 1 *"
                :label-cols="3"
                label-breakpoint="md"
                label-for="idCategoriaDespesa1"
                class="col-md-3"
              >
                <v-select
                  v-validate="'required'"
                  name="idCategoriaDespesa1"
                  :class="{'is-invalid': errors.has('idCategoriaDespesa1')}"
                  id="idCategoriaDespesa1"
                  v-model="idCategoriaDespesa1"
                  :options="selects.categories1"
                  index="value"
                  @change="onChangeIdCategoriaDespesa1"
                />
                <FormError :attribute_name="'idCategoriaDespesa1'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Categoria 2"
                :label-cols="3"
                label-breakpoint="md"
                label-for="idCategoriaDespesa2"
                class="col-md-3"
              >
                <v-select
                  name="idCategoriaDespesa2"
                  :class="{'is-invalid': errors.has('idCategoriaDespesa2')}"
                  id="idCategoriaDespesa2"
                  v-model="idCategoriaDespesa2"
                  :options="selects.categories2"
                  index="value"
                  @change="onChangeIdCategoriaDespesa2"
                  ref="idCategoriaDespesa2"
                />
                <FormError :attribute_name="'idCategoriaDespesa2'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Categoria 3"
                :label-cols="3"
                label-breakpoint="md"
                label-for="idCategoriaDespesa3"
                class="col-md-3"
              >
                <v-select
                  name="idCategoriaDespesa3"
                  :class="{'is-invalid': errors.has('idCategoriaDespesa3')}"
                  id="idCategoriaDespesa3"
                  v-model="idCategoriaDespesa3"
                  :options="selects.categories3"
                  index="value"
                  @change="onChangeIdCategoriaDespesa3"
                  ref="idCategoriaDespesa3"
                />
                <FormError :attribute_name="'idCategoriaDespesa3'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Categoria 4"
                :label-cols="3"
                label-breakpoint="md"
                label-for="idCategoriaDespesa4"
                class="col-md-3"
              >
                <v-select
                  name="idCategoriaDespesa4"
                  :class="{'is-invalid': errors.has('idCategoriaDespesa4')}"
                  id="idCategoriaDespesa4"
                  v-model="idCategoriaDespesa4"
                  :options="selects.categories4"
                  index="value"
                  ref="idCategoriaDespesa4"
                />
                <FormError :attribute_name="'idCategoriaDespesa4'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group
                horizontal
                label="Base Imp.(€) *"
                :label-cols="3"
                label-breakpoint="md"
                label-for="baseImp"
                class="col-md-4"
              >
                <input
                  v-validate="'required'"
                  name="baseImp"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('baseImp')}"
                  type="text"
                  id="baseImp"
                  v-model="baseImp"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'baseImp'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Tipus IVA"
                :label-cols="2"
                label-breakpoint="md"
                label-for="tipusIva"
                class="col-md-4"
              >
                <v-select
                  name="tipusIva"
                  :class="{'is-invalid': errors.has('tipusIva')}"
                  id="tipusIva"
                  v-model="tipusIva"
                  :options="selects.tipusIva"
                  index="value"
                  ref="tipusIva"
                  @change="onChangeTipusIva"
                />
                <FormError :attribute_name="'tipusIva'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="IVA (€)"
                :label-cols="2"
                label-breakpoint="md"
                label-for="iva"
                class="col-md-4"
              >
                <b-form-input type="text" disabled :value="iva" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group
                horizontal
                label="Retenció(€) "
                :label-cols="2"
                label-breakpoint="md"
                label-for="retencio"
                class="col-md-4"
              >
                <input
                  name="retencio"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('retencio')}"
                  type="text"
                  id="retencio"
                  v-model="retencio"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'retencio'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Total (€)"
                :label-cols="2"
                label-breakpoint="md"
                label-for="total"
                class="col-md-4"
              >
                <b-form-input type="text" disabled :value="total" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Mesos amortització"
                :label-cols="4"
                label-breakpoint="md"
                label-for="mesosAmortitzacio"
                class="col-md-4"
              >
                <input
                  v-validate="'max:2'"
                  name="mesosAmortitzacio"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('mesosAmortitzacio')}"
                  type="text"
                  id="mesosAmortitzacio"
                  v-model="mesosAmortitzacio"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'mesosAmortitzacio'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
          </fieldset>
          <b-button-toolbar class="float-right">
            <b-button
              type="button"
              variant="default"
              class="btn-rounded"
              @click="$router.push({ name: 'EditarFacturaProveidor', params: {id: $route.params.idFacturaProveidor}})"
            >
              <i class="fa fa-angle-left mr-xs mb-xs" />Tornar
            </b-button>
            <b-button variant="success" @click="onSubmit" class="btn-rounded">
              <Loader v-if="isUpdating"></Loader>
              <span v-else>
                <i class="fa fa-check mr-xs mb-xs" />Guardar
              </span>
            </b-button>
            <b-dropdown variant="primary" text="Més" class="btn-rounded">
              <b-dropdown-item v-b-modal.small>
                <Loader v-if="isDeleting"></Loader>
                <span v-else>Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-modal
              id="small"
              ref="small"
              body-class="bg-white"
              title="Eliminar registre"
              @ok="deleteRowRequest({$toasted, push: { name: 'EditarFacturaProveidor', params: {id: $route.params.idFacturaProveidor}}, missatge: 'La categoria de despesa de la factura ha estat eliminada!'})"
              cancel-title="No"
              ok-title="Si"
            >
              <div class="d-block text-center">
                <h3>N'estas segur?</h3>
              </div>
            </b-modal>
          </b-button-toolbar>
        </b-form>
      </template>
    </Widget>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "EditarFacturaProveidorCategoriaDespesa",
  components: { vSelect },
  data() {
    return {};
  },
  mounted() {
    this.loadRowRequest({
      $toasted: this.$toasted,
      id: this.getId()
    });
  },
  computed: {
    ...mapState("facturesProveidorsCategoriesDespeses", [
      "isReceiving",
      "isDeleting",
      "isUpdating",
      "isReady",
      "selects",
      "valorsAnteriors",
      "jerarquia"
    ]),
    ...mapGetters("facturesProveidorsCategoriesDespeses", ["getRowValue"]),
    idCategoriaDespesa1: {
      get() {
        return this.getRowValue("idCategoriaDespesa1");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaDespesa1", value });
      }
    },
    idCategoriaDespesa2: {
      get() {
        return this.getRowValue("idCategoriaDespesa2");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaDespesa2", value });
      }
    },
    idCategoriaDespesa3: {
      get() {
        return this.getRowValue("idCategoriaDespesa3");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaDespesa3", value });
      }
    },
    idCategoriaDespesa4: {
      get() {
        return this.getRowValue("idCategoriaDespesa4");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaDespesa4", value });
      }
    },
    mesosAmortitzacio: {
      get() {
        return this.getRowValue("mesosAmortitzacio");
      },
      set(value) {
        this.setRowValue({ id: "mesosAmortitzacio", value });
      }
    },
    baseImp: {
      get() {
        return this.getRowValue("baseImp");
      },
      set(value) {
        this.setRowValue({ id: "baseImp", value });
      }
    },
    retencio: {
      get() {
        return this.getRowValue("retencio");
      },
      set(value) {
        this.setRowValue({ id: "retencio", value });
      }
    },
    tipusIva: {
      get() {
        return this.getRowValue("tipusIva");
      },
      set(value) {
        this.setRowValue({ id: "tipusIva", value });
      }
    },
    iva: function() {
      let iva = 0;
      if (
        this.getRowValue("baseImp") != null &&
        this.getRowValue("percentatgeIva")
      ) {
        iva = (
          (this.getRowValue("baseImp") * this.getRowValue("percentatgeIva")) /
          100
        ).toFixed(2);
      }
      return iva;
    },
    total: function() {
      let total = 0;
      if (
        this.getRowValue("baseImp") != null &&
        this.getRowValue("percentatgeIva")
      ) {
        total =
          parseFloat(this.getRowValue("baseImp")) +
          parseFloat(
            (
              (this.getRowValue("baseImp") *
                this.getRowValue("percentatgeIva")) /
              100
            ).toFixed(2)
          );
        if (this.getRowValue("retencio") != null) {
          total = total - parseFloat(this.getRowValue("retencio"));
        }
      }
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapActions("facturesProveidorsCategoriesDespeses", [
      "loadRowRequest",
      "updateRowRequest",
      "deleteRowRequest",
      "setRowValue",
      "loadCategories2",
      "loadCategories3",
      "loadCategories4",
      "loadTipusIva",
      "loadPercentatgeIva"
    ]),
    getId() {
      return (
        parseInt(this.$route.params.idFacturaProveidor) +
        "-" +
        parseInt(this.$route.params.idLinia)
      );
    },
    onChangeTipusIva(value) {
      this.loadPercentatgeIva(value);
    },
    onSubmit(e) {
      e.preventDefault();
      this.$validator
        .validateAll()
        .then(result => {
          if (!result) {
            return;
          }
          this.updateRowRequest({
            $toasted: this.$toasted,
            push: {
              name: "EditarFacturaProveidor",
              params: {
                id: this.$route.params.idFacturaProveidor
              }
            },
            missatge:
              "Les dades de la factura de proveïdor han estat actualitzades!"
          });
        })
        .catch(() => {});
    },
    onChangeIdCategoriaDespesa1(value) {
      if (this.valorsAnteriors.idCategoriaDespesa1 !== value) {
        this.loadCategories2(value);
        this.$refs.idCategoriaDespesa2.clearSelection();
      }
    },
    onChangeIdCategoriaDespesa2(value) {
      if (this.valorsAnteriors.idCategoriaDespesa2 !== value) {
        this.loadCategories3(value);
        this.$refs.idCategoriaDespesa3.clearSelection();
      }
    },
    onChangeIdCategoriaDespesa3(value) {
      if (this.valorsAnteriors.idCategoriaDespesa3 !== value) {
        this.loadCategories4(value);
        this.$refs.idCategoriaDespesa4.clearSelection();
      }
    }
  }
};
</script>
