import axios from "axios";

const state = {
    llistaFactures: "",
};
const getters = {
    getLlistaFactures: (state) => {
        return state.llistaFactures;
    },
};
const mutations = {
    setLlistaFactures: (state, payload) => {
        state.llistaFactures = payload;
    },
};
const actions = {
    fetchLlistaFactures: (context, query) => {
        axios
            .get("/proveidors", {
                params: {
                    filtres: query,
                },
            })
            .then((response) => {
                context.commit("setLlistaFactures", response.data);
            })
            .catch();
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
