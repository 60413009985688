<template>
    <div class="facturesProveidors">
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item>Reclamacions</b-breadcrumb-item>
            <b-breadcrumb-item>Reclamacions Transportistes</b-breadcrumb-item>
            <b-breadcrumb-item active>Portes</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Reclamacions Transportistes Portes</h2>
        </div>
        <Widget title="Llistat de reclamacions dels transportistes">
            <div class="page-top-line d-flex flex-column justify-content-start align-items-start">
                <div class="d-flex flex-row align-items-center mt-4 mb-4 ml-4">
                    <div class="pr-5 d-flex flex-row justify-content-start align-items-center">
                        <b-dropdown class="nom-transportista" id="dropdown-1" :text="filter.nomTransportista" variant="outline-dark">
                            <b-dropdown-item @click="setNomTransportista('DACHSER', '7')">DACHSER</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DHL', '2')">DHL</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DPD/SEUR', '8')">DPD/SEUR</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('TNT', '5')">TNT</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <b-form-input class="mr-4" v-model="filter.mesFacturacio" placeholder="Mes de facturació"></b-form-input>
                        <b-form-input class="mr-4" v-model="filter.anyFacturacio" placeholder="Any de facturació"></b-form-input>
                    </div>
                    <div v-if="isNomFacturaActive" class="pr-5 d-flex flex-row justify-content-start align-items-center">
                        <b-dropdown id="dropdown-2" :text="filter.nomFactura" variant="outline-dark">
                            <b-dropdown-item @click="setNomFactura('DACHSER', '7')">DACHSER</b-dropdown-item>
                            <b-dropdown-item @click="setNomFactura('DHL', '2')">DHL</b-dropdown-item>
                            <b-dropdown-item @click="setNomFactura('DPD/SEUR', '8')">DPD/SEUR</b-dropdown-item>
                            <b-dropdown-item @click="setNomFactura('TNT', '5')">TNT</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <b-button
                    v-if="filter.idFactura"
                    class="mb-4 mt-4"
                    variant="danger"
                    @click="generateTable(filter.idTrans, filter.dataFilter, filter.dataFilterUntil)"
                    >Cerca</b-button
                >
                <b-button v-else disabled>Cerca</b-button>
            </div>

            <div class="mt-5">
                <b-tabs content-class="mt-3">
                    <b-tab title="No identificades" active>
                        <div v-if="table.showTable">
                            <div class="mb-2 mt-2">
                                <span>Mostrant {{ cantidadBusqueda }} resultats</span>
                            </div>
                            <b-table
                                hover
                                responsive
                                flex
                                selectable
                                :select-mode="'multi'"
                                :selected-variant="'success'"
                                @row-selected="onRowSelected"
                                :items="getDadesMostrarATaula"
                                :fields="table.fields"
                                :busy="getRequestState"
                            >
                                <template #cell(accions)="row">
                                    <b-button size="sm" variant="primary" @click="showDetails(row.item)" class="mr-1">
                                        Mostrar detalls
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </b-tab>
                    <b-tab title="Pes / Import superior" disabled lazy><p>I'm the second tab</p></b-tab>
                    <b-tab title="Reexpedicions gratuïtes" disabled lazy><p>I'm a disabled tab!</p></b-tab>
                    <b-tab title="Reexpedicions gratuïtes" disabled lazy><p>I'm a disabled tab!</p></b-tab>
                </b-tabs>
            </div>
            <RawDisplayer :title="'orders'" :value="createExcelDisabled" hidden />
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import RawDisplayer from "@/components/RawDisplayer/RawDisplayer.vue";

export default {
    name: "ReclamacionsTransportistesProductes",
    components: {
        RawDisplayer,
    },
    methods: {
        ...mapActions("reclamacionsTransportistes", ["getReclamacions", "setDetallsReclamacio"]),
        setNomTransportista(nomTrans, idTrans) {
            this.filter.nomTransportista = nomTrans;
            this.filter.idTrans = idTrans;
        },
        generateTable(idTrans, dataCreacio, dataFi) {
            const payload = {
                token: "f4e55eff-b810-4f19-ba2d-a66939acba40",
                dataInici: this.changeDateFormat(dataCreacio),
                dataFi: this.changeDateFormat(dataFi),
                idTransportista: idTrans,
            };

            this.getReclamacions(payload);

            this.table.showTable = true;
        },
        changeDateFormat(date) {
            if (date) {
                const dArr = date.split("-");
                return dArr[2] + "/" + dArr[1] + "/" + dArr[0].substring(2);
            } else {
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, "0");
                const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yy = today
                    .getFullYear()
                    .toString()
                    .substr(-2);

                today = dd + "/" + mm + "/" + yy;
                return today;
            }
        },
        showDetails(record) {
            this.setDetallsReclamacio(record["Nº Expedició"]);
            this.showDetallReclamacio = true;
        },
        onRowSelected(items) {
            this.itemsToExcel = [];
            for (let item of items) {
                for (let reclamacio of this.getLlistaReclamacions) {
                    if (reclamacio.numExpedicio === item["Nº Expedició"]) {
                        delete reclamacio.idTransportista;
                        delete reclamacio.nomTransportista;
                        delete reclamacio.tipusIncidencia;
                        delete reclamacio.comentaris;
                        delete reclamacio.reclamacioCreatEl;

                        this.itemsToExcel.push(reclamacio);
                    }
                }
            }
        },
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },
    },
    computed: {
        ...mapState("reclamacionsTransportistes", ["llistaReclamacions", "dadesMostrarATaula", "isBusy"]),
        ...mapGetters("reclamacionsTransportistes", [
            "getLlistaReclamacions",
            "getDadesMostrarATaula",
            "getRequestState",
            "getDetallsReclamacio",
        ]),
        cantidadBusqueda() {
            return this.getLlistaReclamacions.length;
        },
        createExcelDisabled() {
            if (this.excelName) {
                return "btn btn-success";
            } else {
                return "btn btn-outline-light disabled";
            }
        },
        isNomFacturaActive() {
            if (this.filter.nomTransportista && this.filter.mesFacturacio && this.filter.anyFacturacio) {
                return true;
            } else {
                return false;
            }
        },
    },
    data() {
        return {
            filter: {
                nomTransportista: "Nom del Transportista",
                mesFacturacio: "",
                anyFacturacio: "",
                idTrans: "",
                nomFactura: "Nom de la factura",
                idFactura: "",
            },
            table: {
                showTable: false,
                fields: [{ key: "Nº Expedició", sortable: true }, { key: "Creat el", sortable: true }, { key: "accions" }],
            },
            showDetallReclamacio: false,
            slide: 0,
            sliding: null,
            excelName: "",
            itemsToExcel: [],
        };
    },
};
</script>

<style lang="scss" scoped></style>
