// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueTouch from "vue-touch";
import { ServerTable } from "vue-tables-2";
import VueTextareaAutosize from "vue-textarea-autosize";
import { VueMaskDirective } from "v-mask";
import VeeValidate, { Validator } from "vee-validate";
import ca from "vee-validate/dist/locale/ca";
import JsonExcel from "vue-json-excel";

import axios from "axios";
import Toasted from "vue-toasted";
import Draggable from "vuedraggable";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import store from "./store";
import router from "./Routes";
import App from "./App";
import layoutMixin from "./mixins/layout";
import { AuthMixin } from "./mixins/auth";
import tablesMixin from "./mixins/tables";
import config from "./config";
import Widget from "./components/Widget/Widget";
import Loader from "./components/Loader/Loader";
import FormError from "./components/Form/FormError";
import RawDisplayer from "./components/RawDisplayer/RawDisplayer";

window.axios = require("axios"); //Si no no funcionen les crides desde vue-tables-2
axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";
const token = localStorage.getItem("token");
if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.component("Widget", Widget);
Vue.component("Loader", Loader);
Vue.component("FormError", FormError);
Vue.use(ServerTable, { theme: "bootstrap4" });
Vue.use(VueTextareaAutosize);
Vue.directive("mask", VueMaskDirective);
Vue.directive("focus", {
    // When the bound element is inserted into the DOM...
    inserted: function(el) {
        // Focus the element
        el.focus();
    },
});
Vue.use(VeeValidate, { fieldsBagName: "fieldsbag" });
Validator.localize("ca", ca);
Vue.mixin(layoutMixin);
Vue.mixin(AuthMixin);
Vue.mixin(tablesMixin);
Vue.use(Toasted, { duration: 10000 });
Vue.component("Draggable", Draggable);
Vue.component("RawDisplayer", RawDisplayer);
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;

axios.defaults.baseURL = "https://www.bogarapp.ovh/BogarWSApplication12/webresources/";

new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
