<template>
    <div class="clients2">
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item active>Informes</b-breadcrumb-item>
            <b-breadcrumb-item active>Reclamacions Proveïdors</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Reclamacions Proveïdors</h2>
        </div>
        <Widget title="Llistat de reclamacions">
            <div class="page-top-line"></div>
            <template v-if="columns.length > 0">
                <v-server-table
                    ref="clients2"
                    name="clients2"
                    :url="endPoint"
                    :columns="columns"
                    :options="getOpcions(getOpcionsVueTable())"
                    @loading="onLoading"
                >
                    <div slot="beforeTable">
                        <b-button-toolbar>
                            <b-button variant="success" @click="resetFiltres($refs.clients2)" class="mb-3"
                                >Reset filtre</b-button
                            >
                            <b-button variant="success" @click="getCsv(getQueryData())" class="mb-3">CSV</b-button>
                        </b-button-toolbar>
                    </div>
                    <div slot="filter__id">
                        <input type="checkbox" v-model="allMarked" @change="toggleAll({ data: $refs.clients2.data })" />
                    </div>
                    <template slot="id" slot-scope="props">
                        <input type="checkbox" @change="unmarkAll()" :value="props.row.id" v-model="markedRows" />
                    </template>
                    <div slot="nom" slot-scope="props">
                        <router-link :to="{ name: 'EditarClient2', params: { id: props.row.id } }">{{
                            props.row.nom
                        }}</router-link>
                    </div>
                    <template slot="accions" slot-scope="props">
                        <b-button-toolbar>
                            <b-button
                                variant="info"
                                size="xs"
                                @click="$router.push({ name: 'EditarClient2', params: { id: props.row.id } })"
                            >
                                <span class="d-none d-md-inline-block">Editar</span>
                                <span class="d-md-none">
                                    <i class="la la-edit"></i>
                                </span>
                            </b-button>
                        </b-button-toolbar>
                    </template>
                </v-server-table>
            </template>
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    name: "Clients2",
    components: {},
    methods: {
        ...mapActions("clients", [
            "loadColumns",
            "setAllMarkedValue",
            "setMarkedRowsValue",
            "unmarkAll",
            "toggleAll",
            "onLoaded",
            "getCsv",
        ]),
    },
    computed: {
        ...mapState("clients", ["columns", "endPoint"]),
        ...mapGetters("clients", ["getOpcionsVueTable", "getAllMarkedValue", "getMarkedRowsValue", "getNumMarkedRows"]),
        allMarked: {
            get() {
                return this.getAllMarkedValue();
            },
            set(value) {
                this.setAllMarkedValue(value);
            },
        },
        markedRows: {
            get() {
                return this.getMarkedRowsValue();
            },
            set(value) {
                this.setMarkedRowsValue(value);
            },
        },
    },
    data() {
        return {
            columnsMock: [],
        };
    },
    created() {
        this.loadColumns();
    },
};
</script>

<style src="./ReclamacionsProveidors.scss" lang="scss" />
