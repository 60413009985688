import isScreen from "@/core/screenHelper";

export const DashboardThemes = {
    LIGHT: "light",
    DARK: "dark",
};

export const MessageStates = {
    READ: "read",
    NEW: "new",
    HIDDEN: "hidden",
};

Object.freeze(DashboardThemes);
Object.freeze(MessageStates);

export default {
    namespaced: true,
    state: {
        sidebarClose: true,
        sidebarStatic: true,
        sidebarActiveElement: null,
        chatOpen: false,
        dashboardTheme: DashboardThemes.DARK,
    },
    mutations: {
        initApp(state) {
            setTimeout(() => {
                state.chatNotificationIcon = true;
                state.chatNotificationPopover = true;
                setTimeout(() => {
                    state.chatNotificationPopover = false;
                }, 1000 * 6);
            }, 1000 * 4);
        },
        toggleSidebar(state) {
            const nextState = !state.sidebarStatic;

            localStorage.sidebarStatic = nextState;
            state.sidebarStatic = nextState;

            if (!nextState && (isScreen("lg") || isScreen("xl"))) {
                state.sidebarClose = true;
            }
        },
        switchSidebar(state, value) {
            if (value) {
                state.sidebarClose = value;
            } else {
                state.sidebarClose = !state.sidebarClose;
            }
        },
        handleSwipe(state, e) {
            if (e.direction === 4 && !state.chatOpen) {
                state.sidebarClose = false;
            }

            if (e.direction === 2 && !state.sidebarClose) {
                state.sidebarClose = true;
                return;
            }

            state.chatOpen = e.direction === 2;
        },
        changeSidebarActive(state, index) {
            state.sidebarActiveElement = index;
        },
        changeTheme(state, payload) {
            state.dashboardTheme = payload;
        },
    },
    actions: {
        initApp({ commit }) {
            commit("initApp");
        },
        toggleSidebar({ commit }) {
            commit("toggleSidebar");
        },
        switchSidebar({ commit }, value) {
            commit("switchSidebar", value);
        },
        handleSwipe({ commit }, e) {
            commit("handleSwipe", e);
        },
        changeSidebarActive({ commit }, index) {
            commit("changeSidebarActive", index);
        },
        changeTheme({ commit }, theme) {
            commit("changeTheme", theme);
        },
    },
};
