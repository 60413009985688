<template>
    <b-navbar class="header d-print-none">
        <b-nav>
            <b-nav-item>
                <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
                    <!-- <i class='la la-bars la-lg' /> -->
                    <span v-if="closeMenuIcon === 'left'" class="glyphicon glyphicon-chevron-left"></span>
                    <span v-if="closeMenuIcon === 'right'" class="glyphicon glyphicon-chevron-right"></span>
                </a>
            </b-nav-item>
        </b-nav>
        <b-nav class="ml-auto">
            <b-nav-item class="d-md-down-none">
                <a href="#" class="px-2">
                    {{ user }}
                </a>
            </b-nav-item>
            <b-nav-item-dropdown class="settingsDropdown d-sm-down-none" no-caret right>
                <template slot="button-content">
                    <i class="la la-cog px-2" />
                </template>
                <b-dropdown-item><i class="la la-user" /> My Account</b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item-button @click="logoutUser"> <i class="la la-sign-out" /> Log Out </b-dropdown-item-button>
            </b-nav-item-dropdown>
        </b-nav>
    </b-navbar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "Header",
    components: {},
    data() {
        return {
            user: "",
            closeMenuIcon: "left",
        };
    },
    mounted() {
        if (localStorage.user) {
            this.user = localStorage.user;
        }
    },
    computed: {
        ...mapState("layout", ["sidebarClose", "sidebarStatic"]),
        firstUserLetter() {
            return (this.user.nom || this.user.email)[0].toUpperCase();
        },
    },
    methods: {
        ...mapActions("layout", ["toggleSidebar", "toggleChat", "switchSidebar", "changeSidebarActive", "initApp"]),
        ...mapActions("auth", ["logoutUser"]),
        toggleSidebarMethod() {
            console.log(this.sidebarStatic);
            if (this.sidebarStatic) {
                this.toggleSidebar();
                this.changeSidebarActive(null);
                this.closeMenuIcon = "right";
            } else {
                this.toggleSidebar();
                const paths = this.$route.fullPath.split("/");
                paths.pop();
                this.changeSidebarActive(paths.join("/"));
                this.closeMenuIcon = "left";
            }
        },
    },
    created() {
        if (window.innerWidth > 576) {
            this.initApp();
        }
    },
};
</script>

<style src="./Header.scss" lang="scss" scoped />
