import axios from "axios";

export async function isAuthenticated(token) {
    if (!token) {
        return;
    }
    try {
        let res = await axios.get("/token/isAuthenticated");
        return res.data.isAuthenticated;
    } catch (error) {
        return false;
    }
}

export const AuthMixin = {
    methods: {
        isAuthenticated,
    },
};
