import axios from "axios";

const state = {
    capResultat: false,
    llistaCentresDeCost: "",
    itemsTable: [],
    itemToEdit: "",
};
const getters = {
    getLlistaCentresDeCost: (state) => {
        return state.llistaCentresDeCost;
    },
    getItemsTable: (state) => {
        return state.itemsTable;
    },
    getItemToEdit: (state) => {
        return state.itemToEdit;
    },
    getCapResultat: (state) => {
        return state.capResultat;
    },
};
const mutations = {
    setLlistaCentresDeCost: (state, payload) => {
        state.llistaCentresDeCost = payload;
    },
    setItemsTable: (state) => {
        let dadesTaula = [];

        for (let centreDeCost of state.llistaCentresDeCost) {
            let dadesObj = {};
            dadesObj["nom"] = centreDeCost.nom;
            dadesObj["creatEl"] = centreDeCost.creatEl;
            dadesObj["creatPer"] = centreDeCost.creatPer;
            dadesObj["id"] = centreDeCost.id;
            dadesTaula.push(dadesObj);
        }

        state.itemsTable = dadesTaula;
    },
    setItemToEdit: (state, id) => {
        for (let centreDeCost of state.llistaCentresDeCost) {
            if (centreDeCost.id === id) {
                state.itemToEdit = centreDeCost;
            }
        }
    },
    setItemChanges: (state, changes) => {
        for (let change in changes) {
            for (let item in state.itemToEdit) {
                if (change === item) {
                    state.itemToEdit[item] = changes[change];
                }
            }
        }
    },
};
const actions = {
    fetchLlistaCentresDeCost: (context, query) => {
        return axios
            .get("/centresCost", {
                params: {
                    maxRegistres: -1,
                    filtres: JSON.stringify(query),
                },
            })
            .then((response) => {
                if (response.data.recordsFiltered !== 0) {
                    context.commit("setLlistaCentresDeCost", response.data.data);
                    context.commit("setItemsTable");
                    context.state.capResultat = false;
                } else {
                    context.state.capResultat = true;
                }
                return true;
            })
            .catch(function(error) {
                console.log(error);
                return false;
            });
    },
    createCentreDeCost: (context, nom) => {
        return axios
            .post("/centresCost", {
                nom: nom,
            })
            .then(function(response) {
                console.log(response.data);
                return true;
            })
            .catch(function(error) {
                console.log(error);
                return false;
            });
    },
    putCentreDeCost: (context, changes) => {
        context.commit("setItemChanges", changes);
        return axios
            .put(`/centresCost/${context.state.itemToEdit.id}`, context.state.itemToEdit)
            .then(function(response) {
                console.log(response);
                return true;
            })
            .catch(function(error) {
                console.log(error);
                return false;
            });
    },
    setCentreDeCostToEdit: (context, id) => {
        context.commit("setItemToEdit", id);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
