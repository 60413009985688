<template>
    <div class="facturesProveidors">
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item>Reclamacions</b-breadcrumb-item>
            <b-breadcrumb-item>Reclamacions Transportistes</b-breadcrumb-item>
            <b-breadcrumb-item active>Productes</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Reclamacions Transportistes</h2>
        </div>
        <Widget title="Llistat de reclamacions dels transportistes">
            <div class="page-top-line d-flex flex-column justify-content-start align-items-start">
                <div class="d-flex flex-row align-items-center mt-4 mb-4 ml-4">
                    <div class="pr-5 d-flex flex-row justify-content-start align-items-center">
                        <label for="dropdown-1" class="pr-3">Nom transportista_:</label>
                        <b-dropdown class="nom-transportista" id="dropdown-1" :text="filter.nomFilter" variant="outline-dark">
                            <b-dropdown-item @click="setNomTransportista('CORREOS', '17')">CORREOS</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DACHSER', '7')">DACHSER</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DACHSER Repo', '11')">DACHSER Repo</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DHL', '2')">DHL</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DHL Repo', '18')">DHL Repo</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DPD/SEUR', '8')">DPD/SEUR</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('DPD/SEUR Repo', '20')">DPD/SEUR Repo</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('GLS LLEIDA', '16')">GLS LLEIDA</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('REDUR', '13')">REDUR</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('TNT', '5')">TNT</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('CBL', '6')">CBL</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('CBL REPO', '23')">CBL REPO</b-dropdown-item>
                            <b-dropdown-item @click="setNomTransportista('UPS', '24')">UPS </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center">
                        <label for="expedicioDate" class="pr-3 text-nowrap">Data de creació:</label>
                        <b-form-datepicker
                            class="expedicio-data"
                            id="expedicioDate"
                            v-model="filter.dataFilter"
                            placeholder="Data d'inici"
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                            locale="es"
                            required
                        ></b-form-datepicker>
                        <label for="expedicioDateUntil" class="pl-3 pr-3 text-nowrap">fins a:</label>
                        <b-form-datepicker
                            class="expedicio-data"
                            id="expedicioDateUntil"
                            v-model="filter.dataFilterUntil"
                            placeholder="Data de fí"
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                            locale="es"
                        ></b-form-datepicker>
                    </div>
                </div>
                <b-button
                    class="mb-4 mt-4"
                    variant="danger"
                    @click="generateTable(filter.idTrans, filter.dataFilter, filter.dataFilterUntil)"
                    >Cerca</b-button
                >
            </div>

            <div v-if="table.showTable">
                <b-row class="table-options">
                    <b-col>
                        <span>Mostrant {{ cantidadBusqueda }} resultats</span>
                    </b-col>
                    <b-col>
                        <b-button variant="outline-info" @click="selectAllRows">Seleccionar tots</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="outline-info" @click="clearSelected">Deseleccionar tots</b-button>
                    </b-col>
                </b-row>
                <b-table
                    hover
                    responsive
                    flex
                    selectable
                    :select-mode="'multi'"
                    :selected-variant="'success'"
                    @row-selected="onRowSelected"
                    :items="getDadesMostrarATaula"
                    :fields="table.fields"
                    :busy="getRequestState"
                    class="mt-4"
                    ref="selectableTable"
                >
                    <template #cell(accions)="row">
                        <b-button size="sm" variant="primary" @click="showDetails(row.item)" class="mr-1">
                            Mostrar detalls
                        </b-button>
                    </template>
                </b-table>
                <b-input-group class="document-name">
                    <b-form-input v-model="excelName" placeholder="Nom del document"></b-form-input>
                    <b-input-group-append>
                        <download-excel v-if="excelName" class="btn btn-success" :data="itemsToExcel" :name="excelName">
                            Generar Document Excel
                        </download-excel>
                        <b-button v-else variant="outline-success" disabled>Generar Document Excel</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <RawDisplayer :title="'orders'" :value="createExcelDisabled" hidden />

            <b-modal
                v-model="showDetallReclamacio"
                centered
                hide-backdrop
                ok-only
                content-class="shadow"
                size="lg"
                :title="`Nº D'EXPEDICIÓ ${getDetallsReclamacio.numExpedicio}`"
            >
                <b-form>
                    <div v-for="(reclamacioDetall, index) in getDetallsReclamacio" :key="index">
                        <b-form-group
                            v-if="!index.match(/imagen|idTransportista|nomTransportista/g) && reclamacioDetall !== ''"
                            :label="index"
                            :label-for="index"
                            label-cols-sm="3"
                        >
                            <b-form-input
                                v-if="index !== 'comentaris'"
                                :id="index"
                                :placeholder="reclamacioDetall"
                                readonly
                                plaintext
                            ></b-form-input>
                            <b-form-textarea
                                v-if="index === 'comentaris'"
                                :id="index"
                                :placeholder="reclamacioDetall"
                                plaintext
                                rows="3"
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                    <b-carousel
                        id="carousel-1"
                        v-model="slide"
                        :interval="0"
                        controls
                        indicators
                        background="#ababab"
                        img-width="1024"
                        img-height="480"
                        style="text-shadow: 1px 1px 2px #333;"
                    >
                        <div v-for="(reclamacioDetall, index) in getDetallsReclamacio" :key="index">
                            <b-carousel-slide
                                v-if="index.match('imagen') && reclamacioDetall !== 'Sin imagenes'"
                                :img-src="reclamacioDetall"
                            ></b-carousel-slide>
                        </div>
                    </b-carousel>
                </b-form>
            </b-modal>
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import RawDisplayer from "@/components/RawDisplayer/RawDisplayer.vue";

export default {
    name: "ReclamacionsTransportistesProductes",
    components: {
        RawDisplayer,
    },
    methods: {
        ...mapActions("reclamacionsTransportistes", ["getReclamacions", "setDetallsReclamacio"]),
        setNomTransportista(nomTrans, idTrans) {
            this.filter.nomFilter = nomTrans;
            this.filter.idTrans = idTrans;
        },
        generateTable(idTrans, dataCreacio, dataFi) {
            const payload = {
                token: "f4e55eff-b810-4f19-ba2d-a66939acba40",
                dataInici: this.changeDateFormat(dataCreacio),
                dataFi: this.changeDateFormat(dataFi),
                idTransportista: idTrans,
            };

            this.getReclamacions(payload);

            this.table.showTable = true;
        },
        changeDateFormat(date) {
            if (date) {
                const dArr = date.split("-");
                return dArr[2] + "/" + dArr[1] + "/" + dArr[0].substring(2);
            } else {
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, "0");
                const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yy = today
                    .getFullYear()
                    .toString()
                    .substr(-2);

                today = dd + "/" + mm + "/" + yy;
                return today;
            }
        },
        showDetails(record) {
            this.setDetallsReclamacio(record["Nº Expedició"]);
            this.showDetallReclamacio = true;
        },
        onRowSelected(items) {
            this.itemsToExcel = [];
            for (let item of items) {
                for (let reclamacio of this.getLlistaReclamacions) {
                    if (reclamacio.numExpedicio === item["Nº Expedició"]) {
                        delete reclamacio.idTransportista;
                        delete reclamacio.nomTransportista;
                        delete reclamacio.comentaris;
                        delete reclamacio.reclamacioCreatEl;

                        this.itemsToExcel.push(reclamacio);
                    }
                }
            }
        },
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows();
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
        },
    },
    computed: {
        ...mapState("reclamacionsTransportistes", ["llistaReclamacions", "dadesMostrarATaula", "isBusy"]),
        ...mapGetters("reclamacionsTransportistes", [
            "getLlistaReclamacions",
            "getDadesMostrarATaula",
            "getRequestState",
            "getDetallsReclamacio",
        ]),
        cantidadBusqueda() {
            return this.getLlistaReclamacions.length;
        },
        createExcelDisabled() {
            if (this.excelName) {
                return "btn btn-success";
            } else {
                return "btn btn-outline-light disabled";
            }
        },
    },
    data() {
        return {
            filter: {
                nomFilter: "selecciona una opció",
                dataFilter: "",
                dataFilterUntil: "",
                idTrans: "",
            },
            table: {
                showTable: false,
                fields: [{ key: "Nº Expedició", sortable: true }, { key: "Creat el", sortable: true }, { key: "accions" }],
            },
            showDetallReclamacio: false,
            slide: 0,
            sliding: null,
            excelName: "",
            itemsToExcel: [],
        };
    },
};
</script>

<style src="./ReclamacionsTransportistesProductes.scss" lang="scss" />
