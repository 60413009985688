<template>
  <div class="dynamic-tables">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item active>Tables Dynamic</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      Tables -
      <span class="fw-semi-bold">Dynamic</span>
    </h2>
    <Widget
      title="<h4>Vue <span class='fw-semi-bold'>Tables</span></h4>"
      collapse
      close
      customHeader
    >
      <p>
        Another table built with
        <a
          href="https://www.npmjs.com/package/vue-tables-2"
          target="_blank"
          rel="noopener noreferrer"
        >vue-tables-2</a>
      </p>
      <v-server-table
        ref="usuaris"
        name="usuaris"
        url="/usuari"
        :columns="columns"
        :options="options"
      >
        <div slot="beforeTable">
          <b-button
            variant="success"
            @click="$refs.usuaris.resetQuery();$refs.usuaris.refresh();"
            class="mb-3"
          >Reset filtre</b-button>
        </div>
        <div slot="filter__id">
          <input
            type="checkbox"
            v-model="allMarked"
            @change="toggleAll()"
          />
        </div>
        <template slot="id" slot-scope="props">
          <input
            type="checkbox"
            @change="unmarkAll()"
            :value="props.row.id"
            v-model="markedRows"
          />
        </template>
      </v-server-table>
    </Widget>
  </div>
</template>

<script>
//import { vueTableColumns } from "./data";
import axios from "axios";
//import config from "../../../config";
import { textsVueTable2 } from "@/mixins/tables";

export default {
  name: "Dynamic",
  components: {},
  methods: {
    unmarkAll() {
      this.allMarked = false;
    },
    toggleAll() {
      this.markedRows = this.allMarked
        ? this.$refs.usuaris.data.map(row => row.id)
        : [];
    }
  },
  data() {
    return {
      columns: [
        "id",
        "nom",
        "cognoms",
        "correuElectronic",
        "alertaIncidencies",
        "actiu",
        "administrador",
        "codi"
      ],
      options: {
        saveState: true,
        filterByColumn: true,
        headings: {
          nom: "Full name"
        },
        headingsTooltips: {
          nom: "Full name tooltip"
        },
        perPage: 10,
        //perPageValues: [10, 20, 30, 40,50],
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "nom",
          "cognoms",
          "correuElectronic",
          "alertaIncidencies",
          "actiu",
          "administrador",
          "codi"
        ],
        sortable: [
          "nom",
          "cognoms",
          "correuElectronic",
          "alertaIncidencies",
          "actiu",
          "administrador",
          "codi"
        ],
        texts: textsVueTable2,
        templates: {
          alertaIncidencies: (h, row) => {
            return this.formatBoolean(row.alertaIncidencies);
          },
          actiu: (h, row) => {
            return this.formatBoolean(row.actiu);
          },
          administrador: (h, row) => {
            return this.formatBoolean(row.administrador);
          }
        },
        listColumns: {}
        /*requestFunction: function(data) {
          return axios
            .get("/usuari/testVueTable2", {
              params: data
            })
            .catch(
              function(e) {
                this.dispatch("error", e);
              }.bind(this)
            );
        },
        responseAdapter({ data }) {
          return {
            data: data.data,
            count: data.count
          };
        }*/
        /*requestAdapter(data) {
          data.query.test = "test";
          return data;
        }*/
      },
      allMarked: false,
      markedRows: []
    };
  },
  mounted() {
    /*axios.get("/usuari/test2VueTable2").then(response => {
      this.options.filterByColumn = true;
      this.options.filterable = ['name', 'position', 'office', 'ext', 'startDate', 'salary'];
      this.columns = response.data.columns;
      this.options.headings = response.data.headings;
      //this.options.filterable = response.data.filterable;
      });*/
    axios.get("/usuari/listColumns").then(response => {
      this.options.listColumns = response.data;
    });
  }
};
</script>

<style src="./Dynamic.scss" lang="scss" scoped />
