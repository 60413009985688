import axios from "axios";
import router from "../Routes";

const state = {
    isFetching: false,
    errorMessage: "",
    creds: {},
};
const getters = {
    getIsFetching: (state) => {
        return state.isFetching;
    },
    getDadesMostrarATaula: (state) => {
        return state.errorMessage;
    },
    getCreds: (state) => {
        return state.creds;
    },
    getErrorMessage: (state) => {
        return state.errorMessage;
    },
};
const mutations = {
    LOGIN_FAILURE(state, payload) {
        state.isFetching = false;
        state.errorMessage = payload;
    },
    LOGIN_SUCCESS(state) {
        state.isFetching = false;
        state.errorMessage = "";
    },
    LOGIN_REQUEST(state) {
        state.isFetching = true;
    },
    SET_CREDS(state, payload) {
        state.creds = payload;
    },
};
const actions = {
    loginUser(context, creds) {
        context.commit("LOGIN_REQUEST");
        if (creds.email.length > 0 && creds.password.length > 0) {
            context.commit("SET_CREDS", creds);
            axios
                .get("/token/getNewToken")
                .then((res) => {
                    if (res.data.token) {
                        axios.defaults.headers.common = { Authorization: `Bearer ${res.data.token}` };
                        localStorage.setItem("token", res.data.token);
                        context.dispatch("associateUser");
                    } else {
                        context.commit("LOGIN_FAILURE", "Something was wrong. Try again");
                    }
                })
                .catch((err) => {
                    context.commit("LOGIN_FAILURE", err);
                });
        } else {
            context.commit("LOGIN_FAILURE", "Something was wrong. Try again");
        }
    },
    associateUser(context) {
        const creds = context.getters["getCreds"];

        const params = {
            usuari: creds.email,
            contrasenya: creds.password,
        };

        axios
            .post("usuari/login", params)
            .then((res) => {
                if (res.data.logged) {
                    localStorage.setItem("user", creds.email);
                    context.dispatch("receiveLogin");
                } else {
                    context.commit("LOGIN_FAILURE", "Usuari o contrasenya incorrectes");
                }
            })
            .catch((err) => {
                context.commit("LOGIN_FAILURE", err);
            });
    },
    logoutUser() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        axios.defaults.headers.common["Authorization"] = "";
        router.push("/login");
    },
    receiveLogin({ commit }) {
        commit("LOGIN_SUCCESS");
        router.push("/app/main");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
