<template>
    <div :class="[{ root: true, sidebarClose, sidebarStatic }, dashboardThemeClass, 'sing-dashboard']">
        <Sidebar />
        <div class="wrap">
            <Header />
            <div class="content">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("layout");

import Sidebar from "@/components/Sidebar/Sidebar";
import Header from "@/components/Header/Header";

import "./Layout.scss";

export default {
    name: "Layout",
    components: { Sidebar, Header },
    methods: {
        ...mapActions(["switchSidebar", "changeSidebarActive", "toggleSidebar"]),
        handleWindowResize() {
            const width = window.innerWidth;

            if (width <= 768 && this.sidebarStatic) {
                this.toggleSidebar();
                this.changeSidebarActive(null);
            }
        },
    },
    computed: {
        ...mapState(["sidebarClose", "sidebarStatic", "dashboardTheme"]),
        dashboardThemeClass: function() {
            return "dashboard-" + this.dashboardTheme;
        },
    },
    created() {
        const staticSidebar = JSON.parse(localStorage.getItem("sidebarStatic"));

        if (staticSidebar) {
            this.$store.state.layout.sidebarStatic = true;
        } else if (!this.sidebarClose) {
            setTimeout(() => {
                this.switchSidebar(true);
                this.changeSidebarActive(null);
            }, 2500);
        }

        this.handleWindowResize();
        window.addEventListener("resize", this.handleWindowResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleWindowResize);
    },
};
</script>

<style src="./Layout.scss" lang="scss" />
