import axios from "axios";

const state = {
    llistaProveidors: "",
    itemsTable: [],
};
const getters = {
    getLlistaProveidors: (state) => {
        return state.llistaProveidors;
    },
    getItemsTable: (state) => {
        return state.itemsTable;
    },
};
const mutations = {
    setLlistaProveidors: (state, payload) => {
        state.llistaProveidors = payload;
    },
    setItemsTable: (state) => {
        let dadesTaula = [];

        for (let proveidor of state.llistaProveidors) {
            let dadesObj = {};
            dadesObj["nom"] = proveidor.nom;
            if (proveidor.actiu === "S") {
                dadesObj["actiu"] = "Si";
            } else if (proveidor.actiu === "N") {
                dadesObj["actiu"] = "No";
            }
            dadesTaula.push(dadesObj);
        }

        state.itemsTable = dadesTaula;
    },
};
const actions = {
    fetchLlistaProveidors: (context, query) => {
        axios
            .get("/proveidors", {
                params: {
                    maxRegistres: -1,
                    filtres: JSON.stringify(query),
                },
            })
            .then((response) => {
                context.commit("setLlistaProveidors", response.data.data);
                context.commit("setItemsTable");
            })
            .catch();
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
