<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item>Mestres</b-breadcrumb-item>
            <b-breadcrumb-item>Centres de cost</b-breadcrumb-item>
            <b-breadcrumb-item>Centres de cost</b-breadcrumb-item>
            <b-breadcrumb-item active>Editar centre de cost</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Editar centre de cost</h2>
        </div>
        <Widget>
            <div class="page-top-line"></div>
            <div class="mt-5">
                <b-form>
                    <fieldset>
                        <b-form-group horizontal label="Nom *" :label-cols="3" label-breakpoint="md" label-for="nom">
                            <input
                                v-validate="'required|alpha'"
                                name="nom"
                                :class="{ 'form-control': true, 'is-invalid': errors.has('nom') }"
                                type="text"
                                id="nom"
                                :placeholder="getItemToEdit.nom"
                                v-model="editarCentreDeCost.nom"
                                v-focus
                            />
                            <FormError :attribute_name="'nom'" :errors_form="errors" />
                        </b-form-group>
                        <b-button-toolbar class="float-right">
                            <b-button variant="success" class="btn-rounded" @click="validateForm">
                                <span> <i class="fa fa-check mr-xs mb-xs" />Guardar </span>
                            </b-button>
                        </b-button-toolbar>
                    </fieldset>
                </b-form>
            </div>
            <RawDisplayer :title="'data'" :value="getItemToEdit" hidden />
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RawDisplayer from "@/components/RawDisplayer/RawDisplayer.vue";
export default {
    name: "MestresCentresDeCostEdit",
    components: { RawDisplayer },
    methods: {
        ...mapActions("mestresCentresDeCost", [
            "fetchLlistaCentresDeCost",
            "createCentreDeCost",
            "setCentreDeCostToEdit",
            "putCentreDeCost",
        ]),
        validateForm() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    let params = {
                        id: this.getItemToEdit.id,
                        nom: this.editarCentreDeCost.nom,
                    };
                    const response = await this.putCentreDeCost(params);

                    if (response) {
                        this.$toasted.success("Centre editat Correctament");
                        this.$router.push({ name: "MestresCentresDeCost" });
                    } else {
                        this.$toasted.error("Error al editar el Centre");
                        this.$router.push({ name: "MestresCentresDeCost" });
                    }
                    return;
                }
            });
        },
    },
    computed: {
        ...mapGetters("mestresCentresDeCost", ["getLlistaCentresDeCost", "getItemsTable", "getItemToEdit"]),
    },
    data() {
        return {
            editarCentreDeCost: {
                nom: "",
            },
        };
    },
};
</script>

<style lang="scss" />
