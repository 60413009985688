import Vue from "vue";
import Vuex from "vuex";

import layout from "./layout";
import auth from "./auth";
import usuaris from "./usuaris";
import facturesProveidors from "./facturesProveidors";
import reclamacionsTransportistes from "./reclamacionsTransportistes";
import mestresProveidors from "./mestresProveidors";
import mestresCentresDeCost from "./mestresCentresDeCost";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        layout,
        auth,
        usuaris,
        facturesProveidors,
        reclamacionsTransportistes,
        mestresProveidors,
        mestresCentresDeCost,
    },
});
