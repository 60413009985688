import Vue from "vue";
import Router from "vue-router";

import Layout from "@/components/Layout/Layout";
// Tables
import TablesDynamicPage from "@/pages/Tables/Dynamic/Dynamic";
// Main
import Dashboard from "@/pages/Dashboard/Dashboard";
// Factures de proveïdors
import FacturesProveidors from "@/pages/FacturesProveidors/FacturesProveidors";
import NovaFacturaProveidor from "@/pages/FacturesProveidors/NovaFacturaProveidor";
import EditarFacturaProveidor from "@/pages/FacturesProveidors/EditarFacturaProveidor/EditarFacturaProveidor";
import NovaFacturaProveidorCategoriaDespesa from "@/pages/FacturesProveidors/EditarFacturaProveidor/CategoriesDespeses/NovaFacturaProveidorCategoriaDespesa";
import EditarFacturaProveidorCategoriaDespesa from "@/pages/FacturesProveidors/EditarFacturaProveidor/CategoriesDespeses/EditarFacturaProveidorCategoriaDespesa";

//Reclamacions
import ReclamacionsProveidors from "@/pages/Reclamacions/ReclamacionsProveidors/ReclamacionsProveidors";
import ReclamacionsTransportistesProductes from "./pages/Reclamacions/ReclamacionsTransportistes/Productes/ReclamacionsTransportistesProductes";
import ReclamacionsTransportistesPortes from "./pages/Reclamacions/ReclamacionsTransportistes/Portes/ReclamacionsTransportistesPortes";

//Mestres
import MestresProveidors from "./pages/Mestres/Proveidors/Proveidors/Proveidors";
import MestresCentresDeCost from "./pages/Mestres/Proveidors/CentresDeCost/CentresDeCost";
import EditarCentreDeCost from "./pages/Mestres/Proveidors/CentresDeCost/EditarCentreDeCost";

// Seguiment de Comandes
import SeguimentComandes from "@/pages/SeguimentComandes/SeguimentComandes.vue";

// Extra
import Login from "@/pages/Login/Login";
import ErrorPage from "@/pages/Error/Error";

import { isAuthenticated } from "./mixins/auth";

Vue.use(Router);

export default new Router({
    routes: [
        { path: "/", redirect: "/app/main" },
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/error",
            name: "Error",
            component: ErrorPage,
        },
        {
            path: "/app",
            name: "Layout",
            component: Layout,
            beforeEnter: async (to, from, next) => {
                let token = localStorage.getItem("token");
                (await isAuthenticated(token)) ? next() : next({ path: "/login" });
            },
            children: [
                // main pages
                {
                    path: "main",
                    name: "Dashboard",
                    component: Dashboard,
                },
                // factures proveïdors
                {
                    path: "facturesProveidors",
                    name: "FacturesProveidors",
                    component: FacturesProveidors,
                },
                {
                    path: "facturesProveidors/crear",
                    name: "NovaFacturaProveidor",
                    component: NovaFacturaProveidor,
                },
                {
                    path: "facturesProveidors/:id",
                    name: "EditarFacturaProveidor",
                    component: EditarFacturaProveidor,
                },
                {
                    path: "facturesProveidors/:idFacturaProveidor/categoriesDespeses/crear",
                    name: "NovaFacturaProveidorCategoriaDespesa",
                    component: NovaFacturaProveidorCategoriaDespesa,
                },
                {
                    path: "facturesProveidors/:idFacturaProveidor/categoriesDespeses/:idLinia",
                    name: "EditarFacturaProveidorCategoriaDespesa",
                    component: EditarFacturaProveidorCategoriaDespesa,
                },
                //reclamacions
                {
                    path: "reclamacions/reclamacionsProveidors",
                    name: "ReclamacionsProveidors",
                    component: ReclamacionsProveidors,
                },
                {
                    path: "reclamacions/reclamacionsTransportistes/productes",
                    name: "ReclamacionsTransportistesProductes",
                    component: ReclamacionsTransportistesProductes,
                },
                {
                    path: "reclamacions/reclamacionsTransportistes/portes",
                    name: "ReclamacionsTransportistesPortes",
                    component: ReclamacionsTransportistesPortes,
                },
                // seguiment comandes
                {
                    path: "seguimentComandes",
                    name: "seguimentComandes",
                    component: SeguimentComandes,
                },
                // tables pages
                {
                    path: "tables/dynamic",
                    name: "TablesDynamicPage",
                    component: TablesDynamicPage,
                },
                //mestres
                {
                    path: "mestres/mestresProveidors/proveidors",
                    name: "MestresProveidors",
                    component: MestresProveidors,
                },
                {
                    path: "mestres/mestresProveidors/centresDeCost",
                    name: "MestresCentresDeCost",
                    component: MestresCentresDeCost,
                },
                {
                    path: "mestres/mestresProveidors/centresDeCost/editar",
                    name: "EditarCentreDeCost",
                    component: EditarCentreDeCost,
                },
            ],
        },
    ],
});
