<template>
    <div class="panel-proveidors">
        <div class="page-top-line">
            <h2 class="page-title">Order Tracking</h2>
        </div>
        <Widget title="Orders">
            <div class="page-top-line d-flex flex-row justify-content-start">
                <h6 class="pr-3 pl-3">Please select a view:</h6>
                <b-dropdown id="dropdown-1" :text="viewSelectText" variant="dark" no-caret class="m-md-2">
                    <b-dropdown-item @click="changeView('General')">General</b-dropdown-item>
                    <b-dropdown-item @click="changeView('Weekly')">Weekly</b-dropdown-item>
                </b-dropdown>
            </div>
            <!-- GENERAL -->
            <div v-if="viewType === 'General'" class="total-wrapper">
                <div class="pretruck">
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>ORDERS</h5>
                        </div>
                        <div class="list-group">
                            <div v-for="(order, index) in orders" :key="index" @click="openDetails($event, 'orders')">
                                <div v-if="!order.setmanaFabricacioSolicitada" class="list-group-item" :id="order.comandaBogar">
                                    {{ order.comandaBogar }} ({{ order.dataComanda }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>PLANNING REQ.</h5>
                        </div>
                        <div class="list-group">
                            <div v-for="(order, index) in orders" :key="index" @click="openDetails($event, 'planningReq')">
                                <div
                                    v-if="order.setmanaFabricacioSolicitada && !order.setmanaFabricacioConfirmada"
                                    class="list-group-item"
                                    :id="order.comandaBogar"
                                >
                                    {{ order.comandaBogar }} ({{ order.setmanaFabricacioSolicitada }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>PLANNING CONF.</h5>
                        </div>
                        <div class="list-group">
                            <div v-for="(order, index) in orders" :key="index" @click="openDetails($event, 'planningConf')">
                                <div
                                    v-if="order.setmanaFabricacioConfirmada && !order.setmanaCarrega"
                                    class="list-group-item"
                                    :id="order.comandaBogar"
                                >
                                    {{ order.comandaBogar }} ({{ order.setmanaFabricacioConfirmada }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>LOADING CONF.</h5>
                        </div>
                        <div class="list-group">
                            <div v-for="(order, index) in orders" :key="index" @click="openDetails($event, 'loadingConf')">
                                <div v-if="order.setmanaCarrega" class="list-group-item" :id="order.comandaBogar">
                                    {{ order.comandaBogar }} ({{ order.setmanaCarrega }})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pending-wrapper">
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>PENDING</h5>
                        </div>
                        <div class="list-group">
                            <div
                                v-for="(order, index) in pendents"
                                :key="index"
                                class="list-group-item"
                                @click="openDetails($event, 'pendents')"
                            >
                                <i class="icon glyphicon glyphicon-align-justify" :id="order.comandaBogar"></i>
                                {{ order.comandaBogar }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="posttruck">
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>TRUCKS</h5>
                        </div>
                        <div class="list-group-trucks">
                            <div
                                slot="footer"
                                class="btn-group list-group-item-addTruck"
                                role="comandes"
                                aria-label="new truck"
                                key="footer"
                            >
                                <button class="btn btn-dark" @click="openTruckModal">
                                    Create Truck
                                </button>
                            </div>
                            <div
                                v-for="(order, index) in trucks"
                                :key="index"
                                class="list-group-item"
                                @click="openDetails($event, 'trucks')"
                            >
                                <i class="icon glyphicon glyphicon-align-justify" :id="order.comandaBogar"></i>
                                {{ order.comandaBogar }}
                            </div>
                        </div>
                    </div>
                    <div class="items-wrapper">
                        <div class="box-header-title">
                            <h5>UNLOADED</h5>
                        </div>
                        <div class="list-group">
                            <div
                                v-for="(order, index) in descarregat"
                                :key="index"
                                class="list-group-item"
                                @click="openDetails($event, 'descarregat')"
                            >
                                <i class="icon glyphicon glyphicon-align-justify" :id="order.comandaBogar"></i>
                                {{ order.comandaBogar }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- WEEKLY -->
            <div v-if="viewType === 'Weekly'" class="weeks-view">
                <div class="week-wraper">
                    <div class="box-header-title">
                        <h5>WEEK 1</h5>
                    </div>
                    <div class="list-group-weekly">
                        <div v-for="(element, index) in solPlanning" :key="index" class="list-group-item">
                            <i
                                class="icon glyphicon glyphicon-align-justify"
                                :id="element.comandaBogar"
                                @click="openDetails($event, 'solPlanning')"
                            ></i>
                            {{ element.comandaBogar }} - Requested Planning
                        </div>
                        <div v-for="(element, index) in confPlanning" :key="index" class="list-group-item">
                            <i
                                class="icon glyphicon glyphicon-align-justify"
                                :id="element.comandaBogar"
                                @click="openDetails($event, 'confPlanning')"
                            ></i>
                            {{ element.comandaBogar }} - Confirmed Planning
                        </div>
                        <div v-for="(element, index) in confCarga" :key="index" class="list-group-item">
                            <i
                                class="icon glyphicon glyphicon-align-justify"
                                :id="element.comandaBogar"
                                @click="openDetails($event, 'confCarga')"
                            ></i>
                            {{ element.comandaBogar }} - Confirmed Loading
                        </div>
                    </div>
                </div>
                <div class="week-wraper">
                    <div class="box-header-title">
                        <h5>WEEK 2</h5>
                    </div>
                    <div class="list-group-weekly">
                        <div v-for="(element, index) in solPlanning" :key="index" class="list-group-item">
                            <i
                                class="icon glyphicon glyphicon-align-justify"
                                :id="element.comandaBogar"
                                @click="openDetails($event, 'solPlanning')"
                            ></i>
                            {{ element.comandaBogar }} - Requested Planning
                        </div>
                    </div>
                </div>
                <div class="week-wraper">
                    <div class="box-header-title">
                        <h5>WEEK 3</h5>
                    </div>
                    <div class="list-group-weekly">
                        <div v-for="(element, index) in confPlanning" :key="index" class="list-group-item">
                            <i
                                class="icon glyphicon glyphicon-align-justify"
                                :id="element.comandaBogar"
                                @click="openDetails($event, 'confPlanning')"
                            ></i>
                            {{ element.comandaBogar }} - Confirmed Planning
                        </div>
                    </div>
                </div>
                <div class="week-wraper">
                    <div class="box-header-title">
                        <h5>WEEK4</h5>
                    </div>
                    <div class="list-group-weekly">
                        <div v-for="(element, index) in confPlanning" :key="index" class="list-group-item">
                            <i
                                class="icon glyphicon glyphicon-align-justify"
                                :id="element.comandaBogar"
                                @click="openDetails($event, 'confPlanning')"
                            ></i>
                            {{ element.comandaBogar }} - Confirmed Planning
                        </div>
                    </div>
                </div>
            </div>

            <!-- ORDER DETAILS -->
            <b-modal
                v-model="orderDetailsModal.show"
                centered
                hide-backdrop
                ok-only
                content-class="shadow"
                size="lg"
                :title="'DETALLS DE LA COMANDA ' + orderDetailsModal.comandaId"
            >
                <form v-for="(order, index) in orders" :key="index">
                    <div v-if="order.comandaBogar === orderDetailsModal.comandaId">
                        <div class="pt-3">
                            <h5 class="font-weight-bold">Codi article: {{ order.codiProveidor }}</h5>
                        </div>
                        <hr />
                        <div class="form-row pt-2">
                            <div class="form-group col-6">
                                <label :for="order.article" class="pt-2">Article Name</label>
                                <input class="form-control" type="text" :id="order.article" :placeholder="order.article" readonly />
                            </div>
                            <div class="form-group col-3">
                                <label for="Magatzem" class="pt-2">Warehouse</label>
                                <input class="form-control" type="text" id="Magatzem" :placeholder="order.magatzem" readonly />
                            </div>
                            <div class="form group col-3">
                                <label for="Quantitat" class="pt-2">Requested Quantity</label>
                                <input class="form-control" type="text" id="Quantitat" :placeholder="order.quantitat" readonly />
                            </div>
                        </div>
                        <div class="form-row pt-2">
                            <div class="form-group col-6">
                                <label for="Data Comanda" class="pt-2">Creation Date</label>
                                <input class="form-control" type="text" id="Data Comanda" :placeholder="order.dataComanda" readonly />
                                <label for="Fabricacio Confirmada" class="pt-2">Planning Confirmation</label>
                                <b-form-datepicker
                                    v-if="orderDetailsModal.planConfAct"
                                    type="text"
                                    id="Fabricacio Confirmada"
                                    :placeholder="order.setmanaFabricacioConfirmada"
                                    :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                                    locale="es"
                                />
                                <b-form-datepicker
                                    v-else
                                    type="text"
                                    id="Fabricacio Confirmada"
                                    :placeholder="order.setmanaFabricacioConfirmada"
                                    :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                                    locale="es"
                                    readonly
                                />
                            </div>
                            <div class="form-group col-6">
                                <label for="Fabricacio Solicitada" class="pt-2">Planning Request</label>
                                <b-form-datepicker
                                    v-if="orderDetailsModal.planReqAct"
                                    class="form-control"
                                    type="text"
                                    id="Fabricacio Solicitada"
                                    :placeholder="order.setmanaFabricacioSolicitada"
                                    :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                                    locale="es"
                                />
                                <b-form-datepicker
                                    v-else
                                    class="form-control"
                                    type="text"
                                    id="Fabricacio Solicitada"
                                    :placeholder="order.setmanaFabricacioSolicitada"
                                    :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                                    locale="es"
                                    readonly
                                />
                                <label for="Data Carrega" class="pt-2">Loading Confirmation</label>
                                <b-form-datepicker
                                    v-if="orderDetailsModal.loadConfAct"
                                    class="form-control"
                                    type="text"
                                    id="Data Carrega request"
                                    :placeholder="order.setmanaCarrega"
                                    :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                                    locale="es"
                                />
                                <b-form-datepicker
                                    v-else
                                    class="form-control"
                                    type="text"
                                    id="Data Carrega request"
                                    :placeholder="order.setmanaCarrega"
                                    :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                                    locale="es"
                                    readonly
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="Observacions" class="pt-2">Observacions</label>
                                <input class="form-control" type="text" id="Observacions" :placeholder="order.observacions" />
                            </div>
                        </div>
                        <hr />
                    </div>
                </form>
                <RawDisplayer :title="'orders'" :value="orderDetailsModal" hidden />
            </b-modal>

            <!-- CREATE TRUCK -->
            <b-modal
                v-model="createTruckModal.show"
                centered
                hide-backdrop
                ok-only
                content-class="shadow"
                size="lg"
                :title="'CREATE TRUCK'"
            >
                <b-form @submit.stop.prevent>
                    <b-form-group label="Orders" label-for="orders">
                        <b-form-select
                            v-model="createTruckModal.formData.orders.orderSelected"
                            :options="ordersAvailableForTrucks"
                            id="order"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group id="truckIdentifier-group" label="TruckIdentifier" label-for="truckIdentifier">
                        <b-form-input
                            id="truckIdentifier"
                            placeholder="Truck Identifier"
                            v-model="createTruckModal.formData.truckIdentifier"
                        >
                            Truck Identifier
                        </b-form-input>
                    </b-form-group>
                    <b-form-group label="Creation Date" label-for="creationDate">
                        <b-form-datepicker
                            id="creationDate"
                            v-model="createTruckModal.formData.creationDate"
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                            locale="es"
                        ></b-form-datepicker>
                    </b-form-group>
                    <b-form-group label="Unload Date Requested" label-for="unloadDateRequested">
                        <b-form-datepicker
                            id="unloadDateRequested"
                            v-model="createTruckModal.formData.unloadDateRequested"
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                            locale="es"
                        ></b-form-datepicker>
                    </b-form-group>
                </b-form>
                <RawDisplayer :title="'orders'" :value="orderDetailsModal" hidden />
            </b-modal>
        </Widget>
    </div>
</template>

<script>
// import { mapActions, mapState, mapGetters } from "vuex";

import RawDisplayer from "@/components/RawDisplayer/RawDisplayer.vue";
import jsontest from "@/jsontest/jsontest.json";

export default {
    name: "PanelProveidors",
    components: {
        RawDisplayer,
    },
    data() {
        return {
            viewType: "",
            viewSelectText: "Select View",
            orders: jsontest,
            trucks: [],
            descarregat: [],
            pendents: [],
            orderDetailsModal: {
                show: false,
                planReqAct: false,
                planConfAct: false,
                loadConfAct: false,
                comandaId: "",
            },
            createTruckModal: {
                show: false,
                formData: {
                    orders: {
                        orderSelected: null,
                        ordersForTrucks: [],
                        ordersToTruck: [],
                    },
                    truckIdentifier: "",
                    creationDate: "",
                    unloadDateRequested: "",
                },
            },
        };
    },
    methods: {
        openDetails(event, column) {
            if (this.orderDetailsModal.show) {
                this.orderDetailsModal.show = false;
                this.orderDetailsModal.comandaId = event.target.id;
            } else if (!this.orderDetailsModal.show) {
                this.orderDetailsModal.show = true;
                this.orderDetailsModal.comandaId = event.target.id;
                if (column === "orders") {
                    this.orderDetailsModal.planReqAct = true;
                    this.orderDetailsModal.planConfAct = false;
                    this.orderDetailsModal.loadConfAct = false;
                } else if (column === "planningReq") {
                    this.orderDetailsModal.planReqAct = false;
                    this.orderDetailsModal.planConfAct = true;
                    this.orderDetailsModal.loadConfAct = false;
                } else if (column === "planningConf") {
                    this.orderDetailsModal.planReqAct = false;
                    this.orderDetailsModal.planConfAct = false;
                    this.orderDetailsModal.loadConfAct = true;
                } else if (column === "loadingConf") {
                    this.orderDetailsModal.planReqAct = false;
                    this.orderDetailsModal.planConfAct = false;
                    this.orderDetailsModal.loadConfAct = false;
                }
            }
        },
        openTruckModal() {
            this.createTruckModal.show = true;
        },
        createTruck() {
            this.trucks.push({
                id: "808",
                comandaBogar: "897",
                detallsComanda: [
                    {
                        magatzem: "Torre-Serona",
                        dataComanda: "07/10/20",
                        setmana: "1 - 04/01/21",
                        setmanaFabricacioSolicitada: "5 - 01/02/21",
                        setmanaFabricacioConfirmada: "5 - 01/02/21",
                        setmanaCarrega: "5 - 01/02/21",
                        dataDescarrega: "",
                        observacions: "",
                        codiProveidor: "3373",
                        article: "Salón Alfa II Blanco",
                        plastic: "",
                        quantitat: 230,
                        pendents: 230,
                        pls: "",
                        udsPls: 0,
                    },
                ],
            });
        },
        changeView(view) {
            this.viewType = view;
            this.viewSelectText = view;
        },
        changeTruckOrders(orders) {
            this.ordersForTrucks = [];
            this.orderForTrucks = orders;
        },
    },
    computed: {
        ordersAvailableForTrucks() {
            let ordersToReturn = [];
            for (const order in this.orders) {
                if (order.setmanaCarrega) {
                    ordersToReturn.push({
                        value: order.comandaBogar,
                        text: order.comandaBogar,
                    });
                }
            }
            this.changeTruckOrders(ordersToReturn);
            return ordersToReturn;
        },
    },
};
</script>

<style src="./SeguimentComandes.scss" lang="scss" />
