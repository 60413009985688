//const hostApi = process.env.NODE_ENV === "development" ? "http://localhost" : "https://flatlogic-node-backend.herokuapp.com";
//const portApi = process.env.NODE_ENV === "development" ? 8080 : "";
//const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}`;
//const baseURLApi = "http://localhost:8080/BogarRestWSApplication/webresources";
const baseURLApi = "https://www.bogarapp.ovh/BogarRestWSApplication/webresources";

export default {
    //hostApi,
    //portApi,
    baseURLApi,
    //remote: "https://flatlogic-node-backend.herokuapp.com",
    //isBackend: process.env.VUE_APP_BACKEND
};
