<template>
  <div class="editar-categoria-despesa">
    <b-breadcrumb>
      <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
      <b-breadcrumb-item>Mestres</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link :to="{ name: 'FacturesProveidors'}">Factures de proveïdors</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link
          :to="{ name: 'EditarFacturaProveidor', params: {id: $route.params.idFacturaProveidor}}"
        >{{jerarquia.numFactura}}</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Nova categoria de despesa de la factura {{jerarquia.numFactura}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">Categoria de despesa de la factura {{jerarquia.numFactura}}</h2>
    <Widget :title="'Nova categoria de despesa de la factura ' + jerarquia.numFactura">
      <Loader v-if="isReceiving" :size="40"></Loader>
      <template v-if="isReady">
        <b-form>
          <fieldset>
            <b-form-row>
              <b-form-group
                horizontal
                label="Categoria *"
                :label-cols="2"
                label-breakpoint="md"
                label-for="idCategoriaProveidor"
                class="col-md-6"
              >
                <v-select
                  v-validate="'required'"
                  name="idCategoriaProveidor"
                  ref="idCategoriaProveidor"
                  :class="{'is-invalid': errors.has('idCategoriaProveidor')}"
                  id="idCategoriaProveidor"
                  v-model="idCategoriaProveidor"
                  :options="selects.categories"
                  index="value"
                  @change="onChangeIdCategoriaProveidor"
                />
                <FormError :attribute_name="'idCategoriaProveidor'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Base Imp.(€) *"
                :label-cols="4"
                label-breakpoint="md"
                label-for="baseImp"
                class="col-md-3"
              >
                <input
                  v-validate="'required'"
                  name="baseImp"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('baseImp')}"
                  type="text"
                  id="baseImp"
                  v-model="baseImp"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'baseImp'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Tipus IVA"
                :label-cols="3"
                label-breakpoint="md"
                label-for="tipusIva"
                class="col-md-3"
              >
                <v-select
                  name="tipusIva"
                  :class="{'is-invalid': errors.has('tipusIva')}"
                  id="tipusIva"
                  v-model="tipusIva"
                  :options="selects.tipusIva"
                  index="value"
                  ref="tipusIva"
                  @change="onChangeTipusIva"
                />
                <FormError :attribute_name="'tipusIva'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
            <b-form-row>
              <b-form-group
                horizontal
                label="IVA (€)"
                :label-cols="2"
                label-breakpoint="md"
                label-for="iva"
                class="col-md-3"
              >
                <b-form-input type="text" disabled :value="iva" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Retenció(€) "
                :label-cols="3"
                label-breakpoint="md"
                label-for="retencio"
                class="col-md-3"
              >
                <input
                  name="retencio"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('retencio')}"
                  type="text"
                  id="retencio"
                  v-model="retencio"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'retencio'" :errors_form="errors" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Total (€)"
                :label-cols="3"
                label-breakpoint="md"
                label-for="total"
                class="col-md-3"
              >
                <b-form-input type="text" disabled :value="total" />
              </b-form-group>
              <b-form-group
                horizontal
                label="Mesos amortització"
                :label-cols="5"
                label-breakpoint="md"
                label-for="mesosAmortitzacio"
                class="col-md-3"
              >
                <input
                  v-validate="'max:2'"
                  name="mesosAmortitzacio"
                  :class="{ 'form-control': true, 'is-invalid': errors.has('mesosAmortitzacio')}"
                  type="text"
                  id="mesosAmortitzacio"
                  v-model="mesosAmortitzacio"
                  pattern="\d+(\.\d{2})?"
                />
                <FormError :attribute_name="'mesosAmortitzacio'" :errors_form="errors" />
              </b-form-group>
            </b-form-row>
          </fieldset>
          <b-button-toolbar class="float-right">
            <b-button
              type="button"
              variant="default"
              class="btn-rounded"
              @click="$router.push({ name: 'EditarFacturaProveidor', params: {id: $route.params.idFacturaProveidor}})"
            >
              <i class="fa fa-angle-left mr-xs mb-xs" />Tornar
            </b-button>
            <b-button variant="success" @click="onSubmit" class="btn-rounded">
              <Loader v-if="isUpdating"></Loader>
              <span v-else>
                <i class="fa fa-check mr-xs mb-xs" />Guardar
              </span>
            </b-button>
          </b-button-toolbar>
        </b-form>
      </template>
    </Widget>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "NovaFacturaProveidorCategoriaDespesa",
  components: { vSelect },
  data() {
    return {};
  },
  mounted() {
    this.initRowRequest({
      $toasted: this.$toasted,
      id: this.getId()
    });
  },
  computed: {
    ...mapState("facturesProveidorsCategoriesDespeses", [
      "isReceiving",
      "isUpdating",
      "isReady",
      "selects",
      "jerarquia"
    ]),
    ...mapGetters("facturesProveidorsCategoriesDespeses", ["getRowValue"]),
    idCategoriaProveidor: {
      get() {
        return this.getRowValue("idCategoriaProveidor");
      },
      set(value) {
        this.setRowValue({ id: "idCategoriaProveidor", value });
      }
    },
    mesosAmortitzacio: {
      get() {
        return this.getRowValue("mesosAmortitzacio");
      },
      set(value) {
        this.setRowValue({ id: "mesosAmortitzacio", value });
      }
    },
    baseImp: {
      get() {
        return this.getRowValue("baseImp");
      },
      set(value) {
        this.setRowValue({ id: "baseImp", value });
      }
    },
    retencio: {
      get() {
        return this.getRowValue("retencio");
      },
      set(value) {
        this.setRowValue({ id: "retencio", value });
      }
    },
    tipusIva: {
      get() {
        return this.getRowValue("tipusIva");
      },
      set(value) {
        this.setRowValue({ id: "tipusIva", value });
      }
    },
    iva: function() {
      let iva = 0;
      if (
        this.getRowValue("baseImp") != null &&
        this.getRowValue("percentatgeIva")
      ) {
        iva = (
          (this.getRowValue("baseImp") * this.getRowValue("percentatgeIva")) /
          100
        ).toFixed(2);
      }
      return iva;
    },
    total: function() {
      let total = 0;
      if (
        this.getRowValue("baseImp") != null &&
        this.getRowValue("percentatgeIva")
      ) {
        total =
          parseFloat(this.getRowValue("baseImp")) +
          parseFloat(
            (
              (this.getRowValue("baseImp") *
                this.getRowValue("percentatgeIva")) /
              100
            ).toFixed(2)
          );
        if (this.getRowValue("retencio") != null) {
          total = total - parseFloat(this.getRowValue("retencio"));
        }
      }
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapActions("facturesProveidorsCategoriesDespeses", [
      "initRowRequest",
      "createRowRequest",
      "setRowValue",
      "loadTipusIva",
      "loadPercentatgeIva"
    ]),
    getId() {
      return parseInt(this.$route.params.idFacturaProveidor);
    },
    onChangeIdCategoriaProveidor(value) {
      this.loadTipusIva(value);
      this.$refs.tipusIva.clearSelection();
    },
    onChangeTipusIva(value) {
      this.loadPercentatgeIva(value);
    },
    onSubmit(e) {
      e.preventDefault();
      this.$validator
        .validateAll()
        .then(result => {
          if (!result) {
            return;
          }
          this.createRowRequest({
            $toasted: this.$toasted,
            push: {
              name: "EditarFacturaProveidor",
              params: {
                id: "idFacturaProveidor"
              }
            },
            missatge:
              "Les dades de la categoria de despesa de la factura de proveïdor han estat creades!"
          });
        })
        .catch(() => {});
    }
  }
};
</script>
