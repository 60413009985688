<template>
    <div class="auth-page">
        <b-container>
            <h5 class="auth-logo">
                <i class="fa fa-circle text-gray"></i>
                Bogar App
                <i class="fa fa-circle text-warning"></i>
            </h5>
            <Widget class="widget-auth mx-auto" title="<h3 class='mt-0'>Benvinguts a Bogar</h3>" customHeader>
                <p class="widget-auth-info">Introduïu Usuari i Contrasenya</p>
                <form class="mt" @submit.prevent="login">
                    <b-alert class="alert-sm" variant="danger" :show="!!getErrorMessage">{{ getErrorMessage }}</b-alert>
                    <div class="form-group">
                        <input class="form-control no-border" ref="email" required type="text" name="email" placeholder="Email" />
                    </div>
                    <div class="form-group">
                        <input
                            class="form-control no-border"
                            ref="password"
                            required
                            type="password"
                            name="password"
                            placeholder="Contrasenya"
                        />
                    </div>
                    <b-button type="submit" size="sm" class="auth-btn mb-3" variant="inverse">
                        {{ getIsFetching ? "Loading..." : "Login" }}
                    </b-button>
                </form>
            </Widget>
        </b-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "LoginPage",
    components: {},
    computed: {
        ...mapGetters("auth", ["getIsFetching", "getErrorMessage"]),
    },
    methods: {
        ...mapActions("auth", ["loginUser", "receiveToken", "receiveLogin"]),
        login() {
            const email = this.$refs.email.value;
            const password = this.$refs.password.value;

            if (email && password) {
                this.loginUser({ email, password });
            }
        },
    },
    created() {
        if (this.isAuthenticated(localStorage.getItem("token"))) {
            this.receiveLogin();
        }
    },
};
</script>
