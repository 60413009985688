<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item>Mestres</b-breadcrumb-item>
            <b-breadcrumb-item>Centres de cost</b-breadcrumb-item>
            <b-breadcrumb-item active>Centres de cost</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Mestre Centres de cost</h2>
        </div>
        <Widget>
            <div class="page-top-line"></div>
            <div class="mt-5">
                <b-tabs content-class="mt-3">
                    <b-tab title="Buscar centre de cost" active>
                        <b-form @submit="search">
                            <b-row class="mb-4">
                                <b-col cols="3">
                                    <b-form-input
                                        class="mb-3 mr-sm-5 mb-sm-0"
                                        v-model="filters.nomCentreDeCost"
                                        placeholder="Nom centre de cost"
                                    ></b-form-input>
                                </b-col>
                                <b-col>
                                    <div>
                                        <b-button variant="primary" @click="search">Busca</b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form>

                        <div v-if="getCapResultat">
                            <span> No s'ha trobat cap resultat </span>
                        </div>

                        <div v-if="getLlistaCentresDeCost">
                            <div class="mb-2 mt-2">
                                <span>Mostrant {{ tableRows }} resultats</span>
                            </div>
                            <div v-if="isBusy" class="d-flex justify-content-center mb-3">
                                <b-spinner label="Loading..."></b-spinner>
                            </div>
                            <b-table
                                :items="getItemsTable"
                                :fields="table.fields"
                                :per-page="table.perPage"
                                :current-page="table.currentPage"
                                @row-clicked="editCentreDeCost"
                                hover
                                head-variant="dark"
                            ></b-table>
                            <b-pagination
                                v-model="table.currentPage"
                                :total-rows="tableRows"
                                :per-page="table.perPage"
                                aria-controls="my-table"
                                align="center"
                            ></b-pagination>
                        </div>
                    </b-tab>
                    <b-tab title="Nou centre de cost">
                        <b-form>
                            <fieldset>
                                <b-form-group horizontal label="Nom *" :label-cols="3" label-breakpoint="md" label-for="nom">
                                    <input
                                        v-validate="'required|alpha'"
                                        name="nom"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('nom') }"
                                        type="text"
                                        id="nom"
                                        v-model="nouCentreDeCost.nom"
                                        v-focus
                                    />
                                    <FormError :attribute_name="'nom'" :errors_form="errors" />
                                </b-form-group>
                                <b-button-toolbar class="float-right">
                                    <b-button variant="success" class="btn-rounded" @click="validateForm">
                                        <span> <i class="fa fa-check mr-xs mb-xs" />Guardar </span>
                                    </b-button>
                                </b-button-toolbar>
                            </fieldset>
                        </b-form>
                    </b-tab>
                </b-tabs>
                <RawDisplayer :title="'data'" :value="getCapResultat" hidden />
            </div>
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RawDisplayer from "@/components/RawDisplayer/RawDisplayer.vue";
export default {
    name: "MestresCentresDeCost",
    components: { RawDisplayer },
    methods: {
        ...mapActions("mestresCentresDeCost", ["fetchLlistaCentresDeCost", "createCentreDeCost", "setCentreDeCostToEdit"]),
        async search(e) {
            e.preventDefault();
            this.isBusy = true;
            const query = this.buildQuery();
            const response = await this.fetchLlistaCentresDeCost(query);
            if (response) {
                this.isBusy = false;
            } else if (!response) {
                this.isBusy = false;
            }
        },
        buildQuery() {
            let query = [];
            let ands = [];

            if (this.filters.nomCentreDeCost) {
                let nomCentreDeCostObj = {
                    nom: "nom",
                    op: "like",
                    valor: "%" + this.filters.nomCentreDeCost + "%",
                };

                ands.push(nomCentreDeCostObj);
            }

            if (this.filters.actiu.selected) {
                let actiuObj = {
                    nom: "actiu",
                    op: "=",
                    valor: this.filters.actiu.selected,
                };

                ands.push(actiuObj);
            }

            query.push(ands);

            return query;
        },
        validateForm() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    const response = await this.createCentreDeCost(this.nouCentreDeCost.nom);
                    console.log(response);
                    if (response) {
                        this.$toasted.success("Centre Creat Correctament");
                        this.$router.push({ name: "MestresCentresDeCost" });
                    } else {
                        this.$toasted.error("Error al crear el Centre");
                        this.$router.push({ name: "MestresCentresDeCost" });
                    }
                    return;
                }
            });
        },
        editCentreDeCost(item) {
            this.setCentreDeCostToEdit(item.id);
            this.$router.push({ name: "EditarCentreDeCost" });
        },
    },
    computed: {
        ...mapGetters("mestresCentresDeCost", ["getLlistaCentresDeCost", "getItemsTable", "getItemToEdit", "getCapResultat"]),
        tableRows() {
            return this.getItemsTable.length;
        },
    },
    data() {
        return {
            filters: {
                nomCentreDeCost: "",
                actiu: {
                    options: [
                        {
                            value: null,
                            text: "-selecciona--",
                        },
                        { value: "S", text: "Si" },
                        { value: "N", text: "No" },
                    ],
                    selected: null,
                },
            },
            isBusy: false,
            table: {
                currentPage: 1,
                perPage: 10,
                fields: ["nom", "creatEl", "creatPer"],
            },
            nouCentreDeCost: {
                nom: "",
            },
        };
    },
};
</script>

<style src="./CentresDeCost.scss" lang="scss" />
