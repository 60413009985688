import Crud from './crud';

var crud = new Crud('/usuari');
const state = {
  ...crud.state,
};
const getters = {
  ...crud.getters
};
const actions = {
  ...crud.actions
};
const mutations = {
  ...crud.mutations
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
