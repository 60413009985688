<template>
    <div class="facturesProveidors">
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item active>Factures de proveïdors</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Factures de proveïdors</h2>
        </div>
        <Widget title="Llistat de factures de proveïdors">
            <div class="page-top-line"></div>
            <b-row class="mt-4">
                <!-- <b-form-select
                        class="mb-3 mr-sm-4 mb-sm-0"
                        v-model="filters.nomFactura.selected"
                        :options="filters.nomFactura.options"
                        :select-size="1"
                    >
                    </b-form-select> -->
                <b-col cols="2">
                    <b-form-input
                        class="mb-3 mr-sm-5 mb-sm-0"
                        v-model="filters.nomProveidor"
                        id="nomProveidor"
                        placeholder="Nom Proveïdor"
                    ></b-form-input>
                </b-col>
                <b-col cols="12" md="auto">
                    <div class="mb-3 d-flex flex-row align-items-center">
                        <label for="dataDesde" class="date-picker-label">Data desde:</label>
                        <b-form-datepicker
                            id="dataDesde"
                            v-model="filters.dataDesde"
                            placeholder="selecciona"
                            hide-header
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                            locale="es"
                            class="date-picker"
                        ></b-form-datepicker>
                    </div>
                    <div class="mb-3 d-flex flex-row align-items-center">
                        <label for="dataFinsa" class="date-picker-label">Fins a:</label>
                        <b-form-datepicker
                            id="dataFinsa"
                            v-model="filters.dataFinsa"
                            placeholder="selecciona"
                            hide-header
                            :date-format-options="{ day: '2-digit', month: '2-digit', year: '2-digit' }"
                            locale="es"
                            class="date-picker"
                        ></b-form-datepicker>
                    </div>
                </b-col>
                <b-col>
                    <div>
                        <b-button variant="primary" @click="searchFactures">Busca</b-button>
                    </div>
                </b-col>
            </b-row>
            <div>
                <div v-if="isBusy" class="d-flex justify-content-center mb-3">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-if="getLlistaFactures">
                    TABLA
                </div>
            </div>
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "FacturesProveidors",
    components: {},
    methods: {
        ...mapActions("facturesProveidors", ["fetchLlistaFactures"]),
        searchFactures: async () => {
            this.isBusy = true;
            const query = this.buildQuery();
            await this.fetchLlistaFactures(query);
            this.isBusy = false;
        },
        buildQuery: () => {
            let query = [
                [
                    {
                        nom: "",
                        op: "",
                        valor: this.fitlers.nomProveidor,
                    },
                    {
                        nom: "",
                        op: "",
                        valor: this.fitlers.dataDesde,
                    },
                    {
                        nom: "",
                        op: "",
                        valor: this.fitlers.dataFins,
                    },
                ],
            ];

            return query;
        },
    },
    computed: {
        ...mapGetters("facturesProveidors", ["getLlistaFactures"]),
    },
    data() {
        return {
            filters: {
                nomFactura: {
                    options: [{ value: "null", text: "-- Proveïdor --" }],
                    selected: "null",
                },
                nomProveidor: "",
                dataDesde: "",
                dataFins: "",
                pagat: "",
            },
            isBusy: false,
        };
    },
};
</script>

<style src="./FacturesProveidors.scss" lang="scss" />
