<template>
    <div class="editar-factura-proveidor">
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link :to="{ name: 'FacturesProveidors' }">Factures de proveïdors</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Editar factura de proveïdor</b-breadcrumb-item>
        </b-breadcrumb>
        <h2 class="page-title">Proveïdor</h2>
        <b-tabs class="mb-lg" v-model="tabIndex" lazy>
            <b-tab title="Factura de proveïdor">
                <Widget :title="'Editar factura de proveïdor'">
                    <Loader v-if="isReceiving" :size="40"></Loader>
                    <template v-if="isReady">
                        <b-form>
                            <fieldset>
                                <b-form-row>
                                    <b-form-group
                                        horizontal
                                        label="Proveïdor *"
                                        :label-cols="2"
                                        label-breakpoint="md"
                                        label-for="idProveidor"
                                        class="col-md-6"
                                    >
                                        <v-select
                                            v-validate="'required'"
                                            name="idProveidor"
                                            :class="{ 'is-invalid': errors.has('idProveidor') }"
                                            id="idProveidor"
                                            v-model="idProveidor"
                                            :options="selects.proveidors"
                                            index="value"
                                            v-focus
                                        />
                                        <FormError :attribute_name="'idProveidor'" :errors_form="errors" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Data *"
                                        :label-cols="2"
                                        label-breakpoint="md"
                                        label-for="dataFactura"
                                        class="col-md-3"
                                    >
                                        <input
                                            v-validate="'required|date_format:dd/MM/yyyy'"
                                            name="dataFactura"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('dataFactura') }"
                                            type="text"
                                            id="dataFactura"
                                            v-model="dataFactura"
                                            placeholder="__/__/____"
                                            v-mask="'##/##/####'"
                                        />
                                        <FormError :attribute_name="'dataFactura'" :errors_form="errors" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Núm.Factura *"
                                        :label-cols="4"
                                        label-breakpoint="md"
                                        label-for="numFactura"
                                        class="col-md-3"
                                    >
                                        <input
                                            v-validate="'required|max:31'"
                                            name="numFactura"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('numFactura') }"
                                            type="text"
                                            id="numFactura"
                                            v-model="numFactura"
                                        />
                                        <FormError :attribute_name="'numFactura'" :errors_form="errors" />
                                    </b-form-group>
                                </b-form-row>
                                <b-form-row>
                                    <b-form-group
                                        horizontal
                                        label="Base Imp.(€)"
                                        :label-cols="4"
                                        label-breakpoint="md"
                                        label-for="iva1"
                                        class="col-md-3"
                                    >
                                        <b-form-input type="text" disabled :value="row2.baseImp" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Retenció(€) "
                                        :label-cols="3"
                                        label-breakpoint="md"
                                        label-for="retencio"
                                        class="col-md-3"
                                    >
                                        <b-form-input type="text" disabled :value="row2.retencio" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="IVA(€) "
                                        :label-cols="2"
                                        label-breakpoint="md"
                                        label-for="iva"
                                        class="col-md-3"
                                    >
                                        <b-form-input type="text" disabled :value="row2.iva" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Total(€)"
                                        :label-cols="2"
                                        label-breakpoint="md"
                                        label-for="total"
                                        class="col-md-3"
                                    >
                                        <b-form-input type="text" disabled :value="row2.total" />
                                    </b-form-group>
                                </b-form-row>
                                <b-form-row>
                                    <b-form-group
                                        horizontal
                                        label="Pagada"
                                        :label-cols="3"
                                        label-breakpoint="md"
                                        label-for="dataPagament"
                                        class="col-md-3"
                                    >
                                        <input
                                            v-validate="'date_format:dd/MM/yyyy'"
                                            name="dataPagament"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('dataPagament') }"
                                            type="text"
                                            id="dataPagament"
                                            v-model="dataPagament"
                                            placeholder="__/__/____"
                                            v-mask="'##/##/####'"
                                        />
                                        <FormError :attribute_name="'dataPagament'" :errors_form="errors" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Comprovat"
                                        :label-cols="3"
                                        label-breakpoint="md"
                                        label-for="comprovat"
                                        class="col-md-3"
                                    >
                                        <input
                                            v-validate="'max:100'"
                                            name="comprovat"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('comprovat') }"
                                            type="text"
                                            id="comprovat"
                                            v-model="comprovat"
                                        />
                                        <FormError :attribute_name="'comprovat'" :errors_form="errors" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Observacions"
                                        :label-cols="2"
                                        label-breakpoint="md"
                                        label-for="observacions"
                                        class="col-md-6"
                                    >
                                        <textarea
                                            v-validate="'max:1000'"
                                            name="observacions"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('observacions') }"
                                            :min-height="75"
                                            id="observacions"
                                            v-model="observacions"
                                        />
                                        <FormError :attribute_name="'observacions'" :errors_form="errors" />
                                    </b-form-group>
                                </b-form-row>
                                <legend>Confirming</legend>
                                <b-form-row>
                                    <b-form-group
                                        horizontal
                                        label="Confirming"
                                        :label-cols="3"
                                        label-breakpoint="md"
                                        label-for="confirming"
                                        class="col-md-3"
                                    >
                                        <b-form-checkbox
                                            name="confirming"
                                            id="confirming"
                                            v-model="confirming"
                                            value="S"
                                            unchecked-value="N"
                                        />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Venciment"
                                        :label-cols="3"
                                        label-breakpoint="md"
                                        label-for="dataVenciment"
                                        class="col-md-3"
                                    >
                                        <input
                                            v-validate="'date_format:dd/MM/yyyy'"
                                            name="dataVenciment"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('dataVenciment') }"
                                            type="text"
                                            id="dataVenciment"
                                            v-model="dataVenciment"
                                            placeholder="__/__/____"
                                            v-mask="'##/##/####'"
                                        />
                                        <FormError :attribute_name="'dataVenciment'" :errors_form="errors" />
                                    </b-form-group>
                                    <b-form-group
                                        horizontal
                                        label="Banc"
                                        :label-cols="1"
                                        label-breakpoint="md"
                                        label-for="bancConfirming"
                                        class="col-md-6"
                                    >
                                        <input
                                            v-validate="'max:100'"
                                            name="bancConfirming"
                                            :class="{ 'form-control': true, 'is-invalid': errors.has('bancConfirming') }"
                                            type="text"
                                            id="bancConfirming"
                                            v-model="bancConfirming"
                                        />
                                        <FormError :attribute_name="'bancConfirming'" :errors_form="errors" />
                                    </b-form-group>
                                </b-form-row>
                                <legend></legend>
                                <b-form-group
                                    horizontal
                                    label="Adjuntar factura"
                                    :label-cols="3"
                                    label-breakpoint="md"
                                    label-for="fileupload1"
                                >
                                    <b-input-group class="fileinput fileinput-new">
                                        <input
                                            @change="onFileChange"
                                            id="fileupload1"
                                            type="file"
                                            name="file"
                                            class="display-none"
                                            accept="application/pdf"
                                        />
                                        <label for="fileupload1" class="form-control">
                                            <div v-if="inputFiles.length > 0">
                                                <span v-for="file in inputFiles" :key="`select-id-${file.name.split('.')[0].toString()}`">{{
                                                    file.name
                                                }}</span>
                                            </div>
                                            <span v-else />
                                        </label>
                                        <b-input-group-append v-if="inputFiles.length === 0">
                                            <b-button type="button" variant="default" class="btn-file">
                                                <label for="fileupload1">Seleccionar fitxer</label>
                                            </b-button>
                                        </b-input-group-append>
                                        <b-input-group-append v-else>
                                            <b-button type="button" variant="default">
                                                <label for="fileupload1">Change file</label>
                                            </b-button>
                                            <b-button type="reset" variant="default" @click="removeFiles">
                                                <label>Remove file</label>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <span class="help-block">Adjuntar la factura escanejada en format PDF.</span>
                                </b-form-group>
                                <a :href="'https://www.bogarfp.ovh/uploads/factures_proveidors/Factura' + id + '.pdf'" target="_blank"
                                    >Veure la factura</a
                                >
                            </fieldset>
                            <b-button-toolbar class="float-right">
                                <b-button
                                    type="button"
                                    variant="default"
                                    class="btn-rounded"
                                    @click="$router.push({ name: 'FacturesProveidors' })"
                                >
                                    <i class="fa fa-angle-left mr-xs mb-xs" />Tornar
                                </b-button>
                                <b-button variant="success" @click="onSubmit" class="btn-rounded">
                                    <Loader v-if="isUpdating"></Loader>
                                    <span v-else> <i class="fa fa-check mr-xs mb-xs" />Guardar </span>
                                </b-button>
                                <b-dropdown variant="primary" text="Més" class="btn-rounded">
                                    <b-dropdown-item v-b-modal.small>
                                        <Loader v-if="isDeleting"></Loader>
                                        <span v-else>Eliminar</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                                <b-modal
                                    id="small"
                                    ref="small"
                                    body-class="bg-white"
                                    title="Eliminar registre"
                                    @ok="
                                        deleteRowRequest({
                                            $toasted,
                                            push: { name: 'FacturesProveidors' },
                                            missatge: 'La factura ha estat eliminada!',
                                        })
                                    "
                                    cancel-title="No"
                                    ok-title="Si"
                                >
                                    <div class="d-block text-center">
                                        <h3>N'estas segur?</h3>
                                    </div>
                                </b-modal>
                            </b-button-toolbar>
                        </b-form>
                    </template>
                </Widget>
            </b-tab>
            <b-tab title="Categories de despeses">
                <Widget title="Llistat de categories de despeses d'aquest proveïdor'">
                    <template v-if="columns1.length > 0">
                        <v-server-table
                            ref="facturaProveidorCategoriesDespeses"
                            name="facturaProveidorCategoriesDespeses"
                            :url="endPoint1"
                            :columns="columns1"
                            :options="getOpcions1()"
                            @loading="onLoading"
                        >
                            <div slot="beforeTable">
                                <b-button-toolbar>
                                    <b-button
                                        variant="success"
                                        @click="
                                            $router.push({
                                                name: 'NovaFacturaProveidorCategoriaDespesa',
                                                params: { idFacturaProveidor: getId() },
                                            })
                                        "
                                        class="mb-3"
                                        >Nova categoria de despesa</b-button
                                    >
                                    <b-button variant="success" class="mb-3" v-b-modal.small :disabled="getNumMarkedRows1() === 0"
                                        >Eliminar seleccionats</b-button
                                    >
                                    <b-modal
                                        id="small"
                                        ref="small"
                                        body-class="bg-white"
                                        title="Eliminar registres seleccionats"
                                        @ok="eliminarSeleccionats1()"
                                        cancel-title="No"
                                        ok-title="Si"
                                    >
                                        <div class="d-block text-center">
                                            <h3>N'estas segur?</h3>
                                        </div>
                                    </b-modal>
                                    <b-button variant="success" @click="resetFiltres($refs.facturaProveidorCategoriesDespeses)" class="mb-3"
                                        >Reset filtre</b-button
                                    >
                                    <b-button variant="success" @click="getCsv1(getQueryData())" class="mb-3">CSV</b-button>
                                </b-button-toolbar>
                            </div>
                            <div slot="filter__id">
                                <input
                                    type="checkbox"
                                    v-model="allMarked1"
                                    @change="toggleAll1({ data: $refs.facturaProveidorCategoriesDespeses.data })"
                                />
                            </div>
                            <template slot="id" slot-scope="props">
                                <input type="checkbox" @change="unmarkAll1()" :value="props.row.id" v-model="markedRows1" />
                            </template>
                            <div slot="categoriaDespesa1" slot-scope="props">
                                <router-link
                                    :to="{
                                        name: 'EditarFacturaProveidorCategoriaDespesa',
                                        params: { idFacturaProveidor: props.row.idFacturaProveidor, idLinia: props.row.idLinia },
                                    }"
                                    >{{ props.row.categoriaDespesa1 }}</router-link
                                >
                            </div>
                            <template slot="accions" slot-scope="props">
                                <b-button-toolbar>
                                    <b-button
                                        variant="info"
                                        size="xs"
                                        @click="
                                            $router.push({
                                                name: 'EditarFacturaProveidorCategoriaDespesa',
                                                params: { idFacturaProveidor: props.row.idFacturaProveidor, idLinia: props.row.idLinia },
                                            })
                                        "
                                    >
                                        <span class="d-none d-md-inline-block">Editar</span>
                                        <span class="d-md-none">
                                            <i class="la la-edit"></i>
                                        </span>
                                    </b-button>
                                </b-button-toolbar>
                            </template>
                        </v-server-table>
                    </template>
                    <b-button-toolbar class="float-right">
                        <b-button type="button" variant="default" class="btn-rounded" @click="$router.push({ name: 'FacturesProveidors' })">
                            <i class="fa fa-angle-left mr-xs mb-xs" />Tornar
                        </b-button>
                    </b-button-toolbar>
                </Widget>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import vSelect from "vue-select";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
    name: "EditarFacturaProveidor",
    components: { vSelect },
    data() {
        return {
            inputFiles: [],
        };
    },
    created() {
        this.loadColumns1();
    },
    mounted() {
        this.loadRowRequest({
            $toasted: this.$toasted,
            id: this.getId(),
        });
    },
    computed: {
        ...mapState("facturesProveidors", ["isReceiving", "isDeleting", "isUpdating", "isReady", "selects", "row2"]),
        ...mapState("facturesProveidorsCategoriesDespeses", {
            columns1: "columns",
            endPoint1: "endPoint",
        }),
        ...mapGetters("facturesProveidors", ["getRowValue", "getTabIndexValue"]),
        ...mapGetters("facturesProveidorsCategoriesDespeses", {
            getOpcionsVueTable1: "getOpcionsVueTable",
            getAllMarkedValue1: "getAllMarkedValue",
            getMarkedRowsValue1: "getMarkedRowsValue",
            getNumMarkedRows1: "getNumMarkedRows",
        }),
        allMarked1: {
            get() {
                return this.getAllMarkedValue1();
            },
            set(value) {
                this.setAllMarkedValue1(value);
            },
        },
        markedRows1: {
            get() {
                return this.getMarkedRowsValue1();
            },
            set(value) {
                this.setMarkedRowsValue1(value);
            },
        },
        tabIndex: {
            get() {
                return this.getTabIndexValue();
            },
            set(value) {
                this.setTabIndexValue(value);
            },
        },
        id: {
            get() {
                return this.getRowValue("id");
            },
            set(value) {
                this.setRowValue({ id: "id", value });
            },
        },
        idProveidor: {
            get() {
                return this.getRowValue("idProveidor");
            },
            set(value) {
                this.setRowValue({ id: "idProveidor", value });
            },
        },
        dataFactura: {
            get() {
                return this.getRowValue("dataFactura");
            },
            set(value) {
                this.setRowValue({ id: "dataFactura", value });
            },
        },
        numFactura: {
            get() {
                return this.getRowValue("numFactura");
            },
            set(value) {
                this.setRowValue({ id: "numFactura", value });
            },
        },
        dataPagament: {
            get() {
                return this.getRowValue("dataPagament");
            },
            set(value) {
                this.setRowValue({ id: "dataPagament", value });
            },
        },
        comprovat: {
            get() {
                return this.getRowValue("comprovat");
            },
            set(value) {
                this.setRowValue({ id: "comprovat", value });
            },
        },
        observacions: {
            get() {
                return this.getRowValue("observacions");
            },
            set(value) {
                this.setRowValue({ id: "observacions", value });
            },
        },
        confirming: {
            get() {
                return this.getRowValue("confirming");
            },
            set(value) {
                this.setRowValue({ id: "confirming", value });
            },
        },
        dataVenciment: {
            get() {
                return this.getRowValue("dataVenciment");
            },
            set(value) {
                this.setRowValue({ id: "dataVenciment", value });
            },
        },
        bancConfirming: {
            get() {
                return this.getRowValue("bancConfirming");
            },
            set(value) {
                this.setRowValue({ id: "bancConfirming", value });
            },
        },
    },
    methods: {
        ...mapActions("facturesProveidors", ["loadRowRequest", "updateRowRequest", "deleteRowRequest", "setRowValue", "setTabIndexValue"]),
        ...mapActions("facturesProveidorsCategoriesDespeses", {
            loadColumns1: "loadColumns",
            deleteRowsRequest1: "deleteRowsRequest",
            setAllMarkedValue1: "setAllMarkedValue",
            setMarkedRowsValue1: "setMarkedRowsValue",
            unmarkAll1: "unmarkAll",
            toggleAll1: "toggleAll",
            onLoaded1: "onLoaded",
            getCsv1: "getCsv",
        }),
        getOpcions1() {
            let options = this.getOpcions(this.getOpcionsVueTable1());
            options.saveState = false;
            options.templates = {
                baseImp: (h, row) => {
                    return this.formatCurrency(row.baseImp);
                },
                iva: (h, row) => {
                    return this.formatCurrency(row.iva);
                },
                retencio: (h, row) => {
                    return this.formatCurrency(row.retencio);
                },
                total: (h, row) => {
                    return this.formatCurrency(row.total);
                },
            };
            options.requestAdapter = (data) => {
                data.query.idFacturaProveidor = this.$route.params.id.toString();
                return data;
            };
            return options;
        },
        getId() {
            return parseInt(this.$route.params.id) || -1;
        },
        onSubmit(e) {
            e.preventDefault();
            this.$validator
                .validateAll()
                .then((result) => {
                    if (!result) {
                        return;
                    }
                    this.updateRowRequest({
                        $toasted: this.$toasted,
                        push: {
                            name: "FacturesProveidors",
                        },
                        missatge: "Les dades de la factura de proveïdor han estat actualitzades!",
                    });
                })
                .catch(() => {});
        },
        onFileChange(e) {
            this.removeFiles();
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.inputFiles.push(files[0]);
            var reader = new FileReader();
            reader.onload = (e) => {
                this.setRowValue({ id: "dataFile", value: e.target.result });
            };
            reader.readAsDataURL(files[0]);
        },

        removeFiles() {
            this.inputFiles = [];
            this.setRowValue({ id: "dataFile", value: null });
        },
        eliminarSeleccionats1() {
            this.deleteRowsRequest1({
                $toasted: this.$toasted,
                $taula: this.$refs.facturaProveidorCategoriesDespeses,
                missatge: "Les categories de despeses seleccionades han estat eliminades!",
            });
        },
    },
};
</script>
