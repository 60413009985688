import axios from "axios";
import router from '../Routes';

export default class {
    constructor(endPoint) {
        this.state = {
            endPoint: endPoint,
            row: {},
            row2: {},
            selects: {},
            valorsAnteriors: {},
            columns: [],
            filterable: [],
            sortable: [],
            listColumns: {},
            headings: {},
            isReady: false,
            isReceiving: false,
            isUpdating: false,
            isDeleting: false,
            tabIndex: 0,
            allMarked: false,
            markedRows: [],
            jerarquia:{}
        };

        this.mutations = {
            RECEIVED_ROW(state, payload) {
                state.row = payload.row;
                if (payload.row2) {
                    state.row2 = payload.row2;
                }

                if (payload.selects) {
                    state.selects = payload.selects;
                }
                if (payload.valorsAnteriors) {
                    state.valorsAnteriors = payload.valorsAnteriors;
                }
                if (payload.jerarquia) {
                    state.jerarquia = payload.jerarquia;
                }
                state.isReady = true;
                state.isReceiving = false;
            },
            RECEIVING_ROW(state) {
                state.isReady = false;
                state.isReceiving = true;
            },
            CREATED_ROW(state, payload) {
                state.row = payload;
                state.isUpdating = false;
                state.isReady = false;
            },
            CREATING_ROW(state) {
                state.isUpdating = true;
            },
            UPDATED_ROW(state, payload) {
                state.row = payload;
                state.isUpdating = false;
            },
            UPDATING_ROW(state) {
                state.isUpdating = true;
            },
            DELETED_ROW(state) {
                state.isDeleting = false;
                state.isReady = false;
            },
            DELETING_ROW(state) {
                state.isDeleting = true;
            },
            DELETED_ROWS(state) {
                state.isDeleting = false;
            },
            DELETING_ROWS(state) {
                state.isDeleting = true;
            },
            RESET_ROW(state) {
                state.isReady = true;
                state.isReceiving = false;
                state.isUpdating = false;
                state.isDeleting = false;
            },
            RECEIVING_COLUMNS(state, payload) {
                if (payload.columns) {
                    state.columns = payload.columns;
                }
                if (payload.filterable) {
                    state.filterable = payload.filterable;
                }
                if (payload.sortable) {
                    state.sortable = payload.sortable;
                }
                if (payload.listColumns) {
                    state.listColumns = payload.listColumns;
                }
                if (payload.headings) {
                    state.headings = payload.headings;
                }
            },
            UPDATE_IS_READY(state, payload) {
                state.isReady = payload.isReady;
            },
            SET_ROW_VALUE(state, payload) {
                state.row[payload.id] = payload.value;
            },
            SET_TAB_INDEX_VALUE(state, payload) {
                state.tabIndex = payload;
            },
            SET_ALL_MARKED_VALUE(state, payload) {
                state.allMarked = payload;
            },
            SET_MARKED_ROWS_VALUE(state, payload) {
                state.markedRows = payload;
            },
            UNMARK_ALL(state) {
                state.allMarked = false;
            },
            TOGGLE_ALL(state, payload) {
                state.markedRows = state.allMarked
                    ? payload.data.map(row => row.id)
                    : [];
            },
            ON_LOADED(state) {
                if (state.allMarked) {
                    state.allMarked = false;
                }
                if (state.markedRows.length !== 0) {
                    state.markedRows = [];
                }
            },
            SET_END_POINT_VALUE(state, payload) {
                state.endPoint = payload;
            }
        };

        this.actions = {
            initRowRequest({ dispatch, getters }, payload) {
                dispatch("receivingRow");
                let url = getters.getEndPoint() + '/initRow';
                if (payload.hasOwnProperty('id')) {
                    url = url + '/' + payload.id;
                }
                axios.get(url).then(res => {
                    dispatch("receiveRow", res.data);
                }).catch(error => {
                    dispatch("resetRow");
                    if (error.response.status === 403) {
                        router.push({ name: 'Forbidden', params: { id: error.response.data } });
                    }
                    else {
                        payload.$toasted.error(error.response.data);
                    }
                });
            },
            loadRowRequest({ dispatch, getters }, payload) {
                dispatch("receivingRow");
                axios.get(getters.getEndPoint() + '/' + payload.id).then(res => {
                    dispatch("receiveRow", res.data);
                }).catch(error => {
                    dispatch("resetRow");
                    if (error.response.status === 403) {
                        router.push({ name: 'Forbidden', params: { id: error.response.data } });
                    }
                    else {
                        payload.$toasted.error(error.response.data);
                    }
                });
            },
            updateRowRequest({ dispatch, getters }, payload) {
                dispatch("updatingRow");
                axios.put(getters.getEndPoint(), getters.getRow()).then(res => {
                    dispatch("updateRow", res.data);
                    if (payload.push) {
                        /*if (payload.push.params) {
                            for (let key in payload.push.params) {
                                payload.push.params[key] = res.data[payload.push.params[key]];
                            }
                        } */
                        router.push(payload.push);
                    }
                    payload.$toasted.success(payload.missatge);
                }).catch(error => {
                    dispatch("resetRow");
                    if (error.response.status === 403) {
                        router.push({ name: 'Forbidden', params: { id: error.response.data } });
                    }
                    else {
                        payload.$toasted.error(error.response.data);
                    }
                });
            },
            createRowRequest({ dispatch, getters }, payload) {
                dispatch("creatingRow");
                axios.post(getters.getEndPoint(), getters.getRow()).then(res => {
                    dispatch("createRow", {});
                    if (payload.push.params) {
                        for (let key in payload.push.params) {
                            if (res.data[payload.push.params[key]]){
                                payload.push.params[key] = res.data[payload.push.params[key]];
                            }
                        }
                    }
                    router.push(payload.push);
                    payload.$toasted.success(payload.missatge);
                }).catch(error => {
                    dispatch("resetRow");
                    if (error.response.status === 403) {
                        router.push({ name: 'Forbidden', params: { id: error.response.data } });
                    }
                    else {
                        payload.$toasted.error(error.response.data);
                    }
                });
            },
            deleteRowRequest({ dispatch, getters }, payload) {
                dispatch("deletingRow");
                let url = getters.getEndPoint() + '/' + getters.getRowValue('id');
                axios.delete(url).then(() => {
                    dispatch("deleteRow");
                    router.push(payload.push);
                    payload.$toasted.success(payload.missatge);
                }).catch(error => {
                    dispatch("resetRow");
                    if (error.response.status === 403) {
                        router.push({ name: 'Forbidden', params: { id: error.response.data } });
                    }
                    else {
                        payload.$toasted.error(error.response.data);
                    }
                });
            },
            deleteRowsRequest({ dispatch, getters }, payload) {
                dispatch("deletingRows");
                axios.delete(getters.getEndPoint() + '/removeRange/' + getters.getMarkedRowsValue()).then(() => {
                    dispatch("deleteRows");
                    payload.$toasted.success(payload.missatge);
                    payload.$taula.refresh();
                }).catch(error => {
                    dispatch("resetRow");
                    if (error.response.status === 403) {
                        router.push({ name: 'Forbidden', params: { id: error.response.data } });
                    }
                    else {
                        payload.$toasted.error(error.response.data);
                    }
                });
            },
            getCsv({ getters }, payload) {
              axios.get(getters.getEndPoint() + '/csv/', {
                responseType: 'blob',
                params: {
                  query: JSON.stringify(payload.query),
                  orderBy: payload.orderBy ? payload.orderBy : '',
                  ascending : payload.ascending
                }
              }).then((res) => {
                var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                var fileLink = document.createElement('a');
          
                fileLink.href = fileURL;
                fileLink.setAttribute('download', getters.getEndPoint().substr(1) + ".csv");
                document.body.appendChild(fileLink);
          
                fileLink.click();
              })
            },
            loadColumns({ dispatch, getters }) {
                if (getters.getColumns().length === 0) {
                    axios.get(getters.getEndPoint() + '/columns').then(res => {
                        dispatch("receivingColumns", res.data);
                    })
                }
            },
            setRowValue({ commit }, payload) {
                commit("SET_ROW_VALUE", payload);
            },
            receiveRow({ commit }, payload) {
                commit("RECEIVED_ROW", payload)
            },
            receivingRow({ commit }) {
                commit("RECEIVING_ROW")
            },
            createRow({ commit }, payload) {
                commit("CREATED_ROW", payload)
            },
            creatingRow({ commit }) {
                commit("CREATING_ROW")
            },
            updateRow({ commit }, payload) {
                commit("UPDATED_ROW", payload)
            },
            updatingRow({ commit }) {
                commit("UPDATING_ROW")
            },
            deleteRow({ commit }) {
                commit("DELETED_ROW")
            },
            deletingRow({ commit }) {
                commit("DELETING_ROW")
            },
            deleteRows({ commit }) {
                commit("DELETED_ROWS")
            },
            deletingRows({ commit }) {
                commit("DELETING_ROWS")
            },
            resetRow({ commit }) {
                commit("RESET_ROW")
            },
            receivingColumns({ commit }, payload) {
                commit("RECEIVING_COLUMNS", payload)
            },
            updateIsReady({ commit }, payload) {
                commit("UPDATE_IS_READY", payload)
            },
            setTabIndexValue({ commit }, payload) {
                commit("SET_TAB_INDEX_VALUE", payload);
            },
            setAllMarkedValue({ commit }, payload) {
                commit("SET_ALL_MARKED_VALUE", payload);
            },
            setMarkedRowsValue({ commit }, payload) {
                commit("SET_MARKED_ROWS_VALUE", payload);
            },
            unmarkAll({ commit }) {
                commit("UNMARK_ALL")
            },
            toggleAll({ commit }, payload) {
                commit("TOGGLE_ALL", payload);
            },
            onLoaded({ commit }) {
                commit("ON_LOADED");
            },
            setEndPointValue({ commit }, payload) {
                commit("SET_END_POINT_VALUE", payload);
            }
        };

        this.getters = {
            getEndPoint: state => () => {
                return state.endPoint;
            },
            getRow: state => () => {
                return state.row;
            },
            getColumns: state => () => {
                return state.columns;
            },
            getOpcionsVueTable: state => () => {
                return {
                    columns: state.columns,
                    filterable: state.filterable,
                    sortable: state.sortable,
                    listColumns: state.listColumns,
                    headings: state.headings
                }

            },
            getRowValue: (state) => (id) => {
                return state.row[id];
            },
            getTabIndexValue: (state) => () => {
                return state.tabIndex;
            },
            getAllMarkedValue: (state) => () => {
                return state.allMarked;
            },
            getMarkedRowsValue: (state) => () => {
                return state.markedRows;
            },
            getNumMarkedRows: (state) => () => {
                return state.markedRows.length;
            }
        };
    }
}