<template>
    <div class="facturesProveidors">
        <b-breadcrumb>
            <b-breadcrumb-item>ETS AQUÍ</b-breadcrumb-item>
            <b-breadcrumb-item>Mestres</b-breadcrumb-item>
            <b-breadcrumb-item>Proveïdors</b-breadcrumb-item>
            <b-breadcrumb-item active>Proveïdors</b-breadcrumb-item>
        </b-breadcrumb>
        <div class="page-top-line">
            <h2 class="page-title">Mestre proveïdors</h2>
        </div>
        <Widget>
            <div class="page-top-line"></div>
            <div class="mt-5">
                <b-tabs content-class="mt-3">
                    <b-tab title="Buscar proveïdor" active>
                        <b-form @submit="search">
                            <b-row class="mb-4">
                                <b-col cols="3">
                                    <b-form-input
                                        class="mb-3 mr-sm-5 mb-sm-0"
                                        v-model="filters.nomProveidor"
                                        placeholder="Nom Proveïdor"
                                    ></b-form-input>
                                </b-col>
                                <b-col cols="12" md="auto" class="d-flex flex-row align-items-center">
                                    <label for="actiu" class="mr-3">Actiu:</label>
                                    <b-form-select
                                        class="mb-3 mr-sm-4 mb-sm-0"
                                        id="actiu"
                                        v-model="filters.actiu.selected"
                                        :options="filters.actiu.options"
                                        :select-size="1"
                                    >
                                    </b-form-select>
                                </b-col>
                                <b-col>
                                    <div>
                                        <b-button variant="primary" @click="search">Busca</b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form>

                        <div v-if="getLlistaProveidors">
                            <div class="mb-2 mt-2">
                                <span>Mostrant {{ tableRows }} resultats</span>
                            </div>
                            <div v-if="isBusy" class="d-flex justify-content-center mb-3">
                                <b-spinner label="Loading..."></b-spinner>
                            </div>
                            <b-table
                                :items="getItemsTable"
                                :fields="table.fields"
                                :per-page="table.perPage"
                                :current-page="table.currentPage"
                                hover
                                head-variant="dark"
                            ></b-table>
                            <b-pagination
                                v-model="table.currentPage"
                                :total-rows="tableRows"
                                :per-page="table.perPage"
                                aria-controls="my-table"
                                align="center"
                            ></b-pagination>
                        </div>
                    </b-tab>
                    <b-tab title="Nou proveïdor" lazy>
                        <b-form>
                            <fieldset>
                                <b-form-group horizontal label="Nom *" :label-cols="3" label-breakpoint="md" label-for="nom">
                                    <input
                                        required
                                        name="nom"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('nom') }"
                                        type="text"
                                        id="nom"
                                        v-model="nouProveidor.nom"
                                        v-focus
                                    />
                                    <FormError :attribute_name="'nom'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Tipus *" :label-cols="3" label-breakpoint="md" label-for="tipus">
                                    <b-select
                                        required
                                        :class="{ 'is-invalid': errors.has('tipus') }"
                                        id="tipus"
                                        v-model="nouProveidor.tipus.selected"
                                        :options="nouProveidor.tipus.options"
                                        index="value"
                                    />
                                    <FormError :attribute_name="'tipus'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Adreça" :label-cols="3" label-breakpoint="md" label-for="adreca">
                                    <input
                                        v-validate="'max:100'"
                                        name="adreca"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('adreca') }"
                                        type="text"
                                        id="adreca"
                                        v-model="nouProveidor.adreca"
                                    />
                                    <FormError :attribute_name="'adreca'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Codi postal" :label-cols="3" label-breakpoint="md" label-for="codiPostal">
                                    <input
                                        v-validate="'max:10'"
                                        name="codiPostal"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('codiPostal') }"
                                        type="text"
                                        id="codiPostal"
                                        v-model="nouProveidor.codiPostal"
                                    />
                                    <FormError :attribute_name="'codiPostal'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Població" :label-cols="3" label-breakpoint="md" label-for="poblacio">
                                    <input
                                        v-validate="'max:100'"
                                        name="poblacio"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('poblacio') }"
                                        type="text"
                                        id="poblacio"
                                        v-model="nouProveidor.poblacio"
                                    />
                                    <FormError :attribute_name="'poblacio'" :errors_form="errors" />
                                </b-form-group>
                                <!-- <b-form-group horizontal label="Pais" :label-cols="3" label-breakpoint="md" label-for="isoCode2">
                                    <v-select
                                        name="isoCode2"
                                        :class="{ 'is-invalid': errors.has('isoCode2') }"
                                        id="isoCode2"
                                        v-model="isoCode2"
                                        :options="selects.paisos"
                                        index="value"
                                    />
                                    <FormError :attribute_name="'isoCode2'" :errors_form="errors" />
                                </b-form-group> -->
                                <b-form-group horizontal label="Correu electrònic" :label-cols="3" label-breakpoint="md" label-for="email">
                                    <input
                                        v-validate="'max:100'"
                                        name="email"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('email') }"
                                        type="text"
                                        id="email"
                                        v-model="nouProveidor.email"
                                    />
                                    <FormError :attribute_name="'email'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Telefon 1" :label-cols="3" label-breakpoint="md" label-for="telefon1">
                                    <input
                                        v-validate="'max:12'"
                                        name="telefon1"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('telefon1') }"
                                        type="text"
                                        id="telefon1"
                                        v-model="nouProveidor.telefon1"
                                    />
                                    <FormError :attribute_name="'telefon1'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Telefon 2" :label-cols="3" label-breakpoint="md" label-for="telefon2">
                                    <input
                                        v-validate="'max:12'"
                                        name="telefon2"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('telefon2') }"
                                        type="text"
                                        id="telefon2"
                                        v-model="nouProveidor.telefon2"
                                    />
                                    <FormError :attribute_name="'telefon2'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="CIF *" :label-cols="3" label-breakpoint="md" label-for="cif">
                                    <input
                                        required
                                        name="cif"
                                        :class="{ 'form-control': true, 'is-invalid': errors.has('cif') }"
                                        type="text"
                                        id="cif"
                                        v-model="nouProveidor.cif"
                                    />
                                    <FormError :attribute_name="'cif'" :errors_form="errors" />
                                </b-form-group>
                                <!-- <b-form-group
                                    horizontal
                                    label="Tipus packing list"
                                    :label-cols="3"
                                    label-breakpoint="md"
                                    label-for="classePackingList"
                                >
                                    <v-select
                                        name="classePackingList"
                                        id="classePackingList"
                                        v-model="classePackingList"
                                        :options="selects.proveidorClassePackinList"
                                        index="value"
                                    />
                                    <FormError :attribute_name="'tipus'" :errors_form="errors" />
                                </b-form-group>
                                <b-form-group horizontal label="Tipus IVA" :label-cols="3" label-breakpoint="md" label-for="tipusIva">
                                    <v-select name="tipusIva" id="tipusIva" v-model="tipusIva" :options="selects.tipusIva" index="value" />
                                    <FormError :attribute_name="'tipusIva'" :errors_form="errors" />
                                </b-form-group> -->
                            </fieldset>
                            <b-button-toolbar class="float-right">
                                <b-button type="button" variant="default" class="btn-rounded" @click="$router.push({ name: 'Proveidors' })">
                                    <i class="fa fa-angle-left mr-xs mb-xs" />Tornar
                                </b-button>
                                <b-button variant="success" @click="onSubmit" class="btn-rounded">
                                    <Loader v-if="isUpdating"></Loader>
                                    <span v-else> <i class="fa fa-check mr-xs mb-xs" />Guardar </span>
                                </b-button>
                            </b-button-toolbar>
                        </b-form>
                    </b-tab>
                </b-tabs>
            </div>
        </Widget>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "MestresProveidors",
    components: {},
    methods: {
        ...mapActions("mestresProveidors", ["fetchLlistaProveidors"]),
        async search(e) {
            e.preventDefault();
            this.isBusy = true;
            const query = this.buildQuery();
            await this.fetchLlistaProveidors(query);
            this.isBusy = false;
        },
        buildQuery() {
            let query = [];
            let ands = [];

            if (this.filters.nomProveidor) {
                let nomProveidorObj = {
                    nom: "nom",
                    op: "like",
                    valor: "%" + this.filters.nomProveidor + "%",
                };

                ands.push(nomProveidorObj);
            }

            if (this.filters.actiu.selected) {
                let actiuObj = {
                    nom: "actiu",
                    op: "=",
                    valor: this.filters.actiu.selected,
                };

                ands.push(actiuObj);
            }

            query.push(ands);

            return query;
        },
    },
    computed: {
        ...mapGetters("mestresProveidors", ["getLlistaProveidors", "getItemsTable"]),
        tableRows() {
            return this.getItemsTable.length;
        },
    },
    data() {
        return {
            filters: {
                nomProveidor: "",
                actiu: {
                    options: [
                        {
                            value: null,
                            text: "-selecciona--",
                        },
                        { value: "S", text: "Si" },
                        { value: "N", text: "No" },
                    ],
                    selected: null,
                },
            },
            isBusy: false,
            table: {
                currentPage: 1,
                perPage: 10,
                fields: ["nom", "actiu"],
            },
            nouProveidor: {
                nom: "",
                tipus: {
                    options: [
                        {
                            value: "A",
                            text: "Materia Prima",
                        },
                        {
                            value: "B",
                            text: "Comptable",
                        },
                    ],
                    selected: "A",
                },
                adreca: "",
                codiPostal: "",
                poblacio: "",
                email: "",
                telefon1: "",
                telefon2: "",
                cif: "",
            },
        };
    },
};
</script>

<style src="./Proveidors.scss" lang="scss" />
